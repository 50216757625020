import { useEffect, useState, useCallback } from "react";
import { sendMessage, addListener, removeListener } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import { useTranslation } from "react-i18next";

const useOpponentsList = () => {
  const [opponents, setOpponents] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en"); // Default to stored language
  const { t } = useTranslation();

  // Memoized WebSocket event handler to prevent unnecessary re-renders
  const handleWebSocketMessage = useCallback((event) => {
    if (event.type === "message" && event.message.messageType === "game_opponents") {
      const data = event.message.gameOpponents?.filter(Boolean).map((opponent) => {
        return {
          name: opponent.name ? t(opponent.name) : "Unknown Opponent",
          description: opponent.description ? t(opponent.description) : "No description available",
          imagePath: opponent.imagePath?.replaceAll(/^"|"$/g, ""), // Fixing regex for string cleanup
          videoPath: opponent.videoPath?.replaceAll(/^"|"$/g, ""),
          gameType: opponent.gameType || "unknown",
          gameTypeId: opponent.gameTypeId || "0",
          gameOpponentId: opponent.gameOpponentId,
          active: opponent.active,
          players: opponent.players
        };
      });

      // Update state only if data has changed
      setOpponents((prev) => (JSON.stringify(prev) !== JSON.stringify(data) ? data : prev));
      localStorage.setItem("opponents", JSON.stringify(data)); // Persist updated list
    }
  }, [t]); // `t` is used inside, so it's a dependency

  useEffect(() => {
    addListener(handleWebSocketMessage);
    
    // Request opponent list if language changes or first load
    const storedOpponents = sessionStorage.getItem("opponents");
    if (!storedOpponents || JSON.parse(storedOpponents).length === 0 || localStorage.getItem("i18nextLng") !== language) {
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "game_opponents";
      sendMessage(generalMessage);
      setLanguage(localStorage.getItem("i18nextLng")); // Update language only when needed
    }

    return () => removeListener(handleWebSocketMessage);
  }, [handleWebSocketMessage, language]); // Depend only on necessary values

  return opponents;
};

export default useOpponentsList;
