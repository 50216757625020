import React, { useState } from "react";

import UserGames from "./UserGames";


const Test = () => {

    const [background, setBackground] = useState({
      image: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415495/Pictures/cyume5jns2oovggj8z4g.png",
      size: "cover",
      position: "center",
      zIndex: -1, // Places it behind all other elements
    });
    
 
  return (
    <UserGames userDataFromApp={null} updateBackground={setBackground}
     
    />
  );
};
export default Test;


