import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
  Alert,
  Divider,
} from "@mui/material";
import {
  Google as GoogleIcon,
  Facebook as FacebookIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../authMethods/firebaseConfig"; // Ensure this file contains Firebase config
import {
  startAuthentication,

} from "@simplewebauthn/browser"; // For Passkeys
import { useTranslation } from "react-i18next"; // Added import for translations
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyIcon from "@mui/icons-material/Key";
import LoginIcon from "@mui/icons-material/Login";
import { GENERAL } from "../general/General";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize translations
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  //const [message, setMessage] = useState(null);

  // Email & Password Sign-Up
  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMsg("");

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      let user = userCredential.user;
      // Send verification email
      await sendEmailVerification(user);
      //setMessage("Please check your email for verification");
     
      Swal.fire({
        title: t("Please check your email for verification"),
        icon: "success",
        draggable: true,
        confirmButtonText: t("OK"),
      });
      return {
        success: true,
        message: "Please check your email for verification",
      };
    } catch (error) {
      setErrorMsg(error.message);
      
      Swal.fire({
        title: t("Error"),
        text: t(error.message),
        icon: 'error',
        draggable: true,
        confirmButtonText: t("OK"),
      })
      return { success: false, message: error.message };
    } finally {
      setLoading(false);
    }
  };

  // Google Sign-In
  const handleGoogleSignIn = async () => {
    setLoading(true);
    setErrorMsg("");

    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("Google Sign-In Success:", result.user);
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Facebook Sign-In
  const handleFacebookSignIn = async () => {
    setLoading(true);
    setErrorMsg("");

    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log("Facebook Sign-In Success:", result.user);
    } catch (error) {
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Passkey Authentication (WebAuthn)
  const handlePasskeyAuth = async () => {
    setLoading(true);
    setErrorMsg("");

    try {
      const response = await startAuthentication();
      console.log("Passkey Auth Success:", response);
    } catch (error) {
      setErrorMsg("Passkey authentication failed.");
    } finally {
      setLoading(false);
    }
  };

  // Facebook Sign-In
  const handleLogIn = async () => {
    navigate("/login")
  };

  const handleBack = () => {
    navigate(-1); // 👈 This navigates one step back in history
  };

  return (
     <Container
           maxWidth="xs"
           sx={{
             
             backgroundImage: GENERAL.BACKGROUND,
            
           }}
         >
       <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={2}
              color={"black"}
            >
         <Typography variant="h7" gutterBottom sx={{ color: 'white' }}>
          {t("SIGN UP")}
        </Typography>

        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <Box
          sx={{
            flexDirection: "column", // Arrange boxes horizontally
            justifyContent: "space-around", // Evenly space items
            alignItems: "center", // Align items vertically
            textAlign: "center",
            backgroundColor: "#f1f8e9",
            border: "5px solid #ccc", // Add border around the Stack
            padding: "5px",
            borderRadius: "10px",
            boxShadow: "8px 2px 3px black", // Optional shadow for emphasis
            margin: "5px", // Optional margin around the box
          }}
        >
          <Button
            fullWidth
            variant="contained"
            startIcon={<GoogleIcon />}
            sx={{
              mb: 1, mt: 1,
              bgcolor: "#db4437",
              "&:hover": { bgcolor: "#b33629" },
            }}
            onClick={handleGoogleSignIn}
            disabled={loading}
          >
            {t("Sign Up with Google")}
          </Button>

          <Button
            fullWidth
            variant="contained"
            startIcon={<FacebookIcon />}
            sx={{
              mb: 1,
              bgcolor: "#1877f2",
              "&:hover": { bgcolor: "#1558b0" },
            }}
            onClick={handleFacebookSignIn}
            disabled={loading}
          >
            {t("Sign Up with Facebook")}
          </Button>

          <Button
            fullWidth
            variant="contained"
            color="success"
            startIcon={<LockIcon />}
            onClick={handlePasskeyAuth}
            disabled={loading}
            sx={{ mb: 1 }}
          >
            {t("Sign Up with Passkey")}
          </Button>
        </Box>

        <Divider sx={{ width: "100%", mb: 1, color: "white"  }}>{t("OR")}</Divider>

        <Box
          sx={{
            flexDirection: "column", // Arrange boxes horizontally
            justifyContent: "space-around", // Evenly space items
            alignItems: "center", // Align items vertically
            textAlign: "center",
            backgroundColor: "#f1f8e9",
            border: "5px solid #ccc", // Add border around the Stack
            padding: "5px",
            borderRadius: "10px",
            boxShadow: "8px 2px 3px black", // Optional shadow for emphasis
            margin: "5px", // Optional margin around the box
          }}
        >

        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
          <TextField
            fullWidth
            label={t("Email")}
            variant="outlined"
            {...register("email", { required: "Email is required" })}
            error={!!errors.email}
            helperText={errors.email?.message}
            size="small" // ✅ makes it more compact
            sx={{ mb: 1 }}
          />

          <TextField
            fullWidth
            label={t("Password")}
            type="password"
            variant="outlined"
            {...register("password", {
              required: t("Password is required"),
              minLength: {
                value: 6,
                message: t("Password must be at least 6 characters"),
              },
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
            size="small" // ✅ makes it more compact
            sx={{ mb: 1 }}
          />

          <Button
          sx={{
            mb: 1,
            bgcolor: "#00b0ff",
            "&:hover": { bgcolor: "#0091ea" },
          }}
            type="submit"
            fullWidth
            variant="contained"
            
            disabled={loading}
          >
            <KeyIcon fontSize="small" sx={{ mr: 1 }} />
            {loading ? <CircularProgress size={24} /> : t("Sign Up")}
          </Button>
        </form>
        </Box>
          
        <Divider sx={{ width: "100%", mt: 1, color: "white" }}>
        {t("HAVE AN ACCOUNT?")}
        </Divider>

        <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleLogIn}
                  disabled={loading}
                  sx={{ mt: 2, bgcolor: "#ff5722",
                    "&:hover": { bgcolor: "#f57f17" } }}
                >
                  <LoginIcon fontSize="small" sx={{ mr: 1 }} />
                  {loading ? <CircularProgress size={24} /> : t("Login")}
                </Button>

                <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleBack}
        disabled={loading}
        sx={{
          mt: 5,
          marginBottom: "5px",
          bgcolor: "#778899",
          "&:hover": { bgcolor: "#87ceeb" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <ArrowBackIcon fontSize="small" />
        {t("Back")}
      </Button>
        
        
      </Box>
    </Container>
  );
};

export default SignUp;
