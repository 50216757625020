import React, { useState } from "react";
import { Routes, Route, Navigate} from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import UserDataSync from "./authMethods/UserDataSync.js";
import ResponsiveChessPage from "./components/ResponsiveChessPage.js";
import MainPage from "./components/MainPage.js";
import ResponsiveChessPageLearn from "./components/ResponsiveChessPageLearn.js";
import { WebSocketProvider } from "./services/WebSocketProvider.js";
import Test from "./components/Test.js";
import UserGames from "./components/UserGames.js";
import WelcomePage from "./components/WelcomePage.js";
import Favorites from "./components/Favorites.js";
import { useWebSocketManager } from "./services/useWebSocketManager";
import GlobalDialog from "./components/GlobalDialog";
import SignUp from "./components/SignUp.js";
import SignIn from "./components/SignIn.js";
import { useUser } from "./authMethods/UserContext";
import { GENERAL } from "./general/General";

import DemocraticChessOverview from "./components/DemocraticChessOverview.js";


const App = () => {
  
  const { connectionStatus, generalMessage} = useWebSocketManager();
  const { userData } = useUser();
  
  //const shouldShowAppBar = !((location.pathname === "/chess") ); // Hide AppBar // || (location.pathname === "/main")
  const [showAppBar, setShowAppBar] = useState(true); // Initial state is true

  const [background, setBackground] = useState({
    image: GENERAL.BACKGROUND,
    size: "cover",
    position: "center",
    zIndex: -1, // Places it behind all other elements
  });
  
  return (
    <div
      className="background-container"
      style={{
        backgroundImage: `url(${background.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
       {/* Conditional rendering for AppBar */}
       {showAppBar && (
        <div style={{ position: "relative", top: 0, width: "100%", zIndex: 1000, marginLeft: "auto", marginRight: "auto" }}>
          <ResponsiveAppBar websocketStatus={connectionStatus} userData={userData} />
          <UserDataSync />
        </div>
      )}

      <div
        style={{
          
          display: "flex",
          //flex: 2,
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "0px",
          overflow: "hidden", // Prevent scrolling
        }}
      >
      <WebSocketProvider>
      <GlobalDialog />
        <Routes>
         
          <Route path="/" element={<Navigate to="/welcome" />} />
          <Route path="/main" element={<MainPage userData={userData} updateBackground={setBackground} setShowAppBar={setShowAppBar}/>} />
          <Route
            path="/chess"
            element={
              <ResponsiveChessPage
                generalMessage={generalMessage}
                updateBackground={setBackground}
                userData={userData}
              />
            }
          />
          <Route
            path="/invited/:invitationId/:invitePlayerColor"
            element={
              <ResponsiveChessPage
                generalMessage={generalMessage}
                updateBackground={setBackground}
                viewer={true}
                userData={userData}
              />
            }
          />
          <Route
            path="/friend/:id"
            element={
              <WelcomePage
                friend={true}
                userData={userData}
              />
            }
          />
          <Route path="/about" element={<DemocraticChessOverview userData={userData} updateBackground={setBackground}/>} />
          <Route path="/learn" element={<ResponsiveChessPageLearn userData={userData} updateBackground={setBackground}/>} />
          <Route path="/test" element={ <Test userData={userData}/>} />
          <Route path="/welcome" element={ <WelcomePage userData={userData} updateBackground={setBackground}/>} />
          <Route path="/signup" element={ <SignUp/>} updateBackground={setBackground} />
          <Route path="/login" element={ <SignIn/>} updateBackground={setBackground} />
          <Route path="/favorites" element={ <Favorites userData={userData} updateBackground={setBackground}/>} />
          <Route path="/games" element={ <UserGames userData={userData} updateBackground={setBackground}/>} />
          
        </Routes>
      </WebSocketProvider>
      </div>
    </div>
  );
};

export default App;
