import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CloseIcon from "@mui/icons-material/Close";
import BalanceIcon from "@mui/icons-material/Balance";
import { useTranslation } from "react-i18next";
import Profile from "./Profile.js";

const GameInfoDialog = ({
  open,
  onClose,
  gameInfo,
  operations,
  onContinue,
}) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [levelData, setLevelData] = useState(null);

  useEffect(() => {
    if (open) {
      const storedLevels = JSON.parse(localStorage.getItem("levels"));

      if (!storedLevels || !Array.isArray(storedLevels)) {
        console.error("No levels found in localStorage or incorrect format.");
        return;
      }

      let lastSelectedLevelId = localStorage.getItem("lastSelectedLevelId");
      let levelsObj = storedLevels.find(
        (level) => level.levelTypeId === +lastSelectedLevelId
      );

      if (levelsObj) {
        setLevelData(levelsObj);
      }
    }
  }, [open]);

  const handleAvatarClick = (avatar) => {
    setSelectedAvatar(avatar);
    setDialogOpen(true);
  };

  function onSurrender() {
    onClose();
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 1, zIndex: 100 }}>
      <Profile
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        avatar={selectedAvatar}
      />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
          zIndex: 3000,
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            padding: "20px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.2rem",
            color: "black",
          }}
        >
          {t("Game Information")}
        </DialogTitle>

        <DialogContent>
          {gameInfo ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "10px 20px",
                backgroundColor: "rgba(245, 245, 245, 1)",
                borderRadius: "10px",
                boxShadow: "inset 0 1px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Points for Win */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor: "#e3f2fd",
                }}
              >
                <EmojiEventsIcon sx={{ color: "#1e88e5", fontSize: "2rem" }} />
                <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                  alt="Score Icon"
                  sx={{
                    width: "50px", // Adjust size if needed
                    height: "50px",
                    marginBottom: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1565c0" }}
                >
                  {t("Win Points")}: {levelData?.win}
                </Typography>

                <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                  alt="Score Icon"
                  sx={{
                    width: "50px", // Adjust size if needed
                    height: "50px",
                    marginBottom: "8px",
                  }}
                />

                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1565c0" }}
                >
                  {t("Win Coins")}: {levelData?.winCoins}
                </Typography>
              </Box>

              {/* Points for Lose */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor: "#ffebee",
                }}
              >
                <CloseIcon sx={{ color: "#d32f2f", fontSize: "2rem" }} />
                <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                  alt="Score Icon"
                  sx={{
                    width: "50px", // Adjust size if needed
                    height: "50px",
                    marginBottom: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#c62828" }}
                >
                  {t("Loss Points")}: {levelData?.lose}
                </Typography>
                <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                  alt="Score Icon"
                  sx={{
                    width: "50px", // Adjust size if needed
                    height: "50px",
                    marginBottom: "8px",
                  }}
                />

                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1565c0" }}
                >
                  {t("Loss Coins")}: {levelData?.loseCoins}
                </Typography>
              </Box>

              {/* Points for Draw */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor: "#f3e5f5",
                }}
              >
                <BalanceIcon sx={{ color: "#7b1fa2", fontSize: "2rem" }} />
                <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                  alt="Score Icon"
                  sx={{
                    width: "50px", // Adjust size if needed
                    height: "50px",
                    marginBottom: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#6a1b9a" }}
                >
                  {t("Draw Points")}: {levelData?.draw}
                </Typography>
                <Box
                  component="img"
                  src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                  alt="Score Icon"
                  sx={{
                    width: "50px", // Adjust size if needed
                    height: "50px",
                    marginBottom: "8px",
                  }}
                />

                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1565c0" }}
                >
                  {t("Draw Coins")}: {levelData?.drawCoins}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ textAlign: "center", padding: "20px" }}
            >
              {t("No game information available")}
            </Typography>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="primary"
            sx={{ padding: "10px 20px", fontSize: "0.8rem" }}
          >
            {t("Close")}
          </Button>

          {operations && (
            <Button
              onClick={onContinue}
              variant="contained"
              color="primary"
              sx={{ padding: "10px 20px", fontSize: "0.8rem" }}
            >
              {t("Continue the game")}
            </Button>
          )}

          {operations && (
            <Button
              onClick={onSurrender}
              variant="contained"
              color="error"
              sx={{ padding: "10px 20px", fontSize: "0.8rem" }}
            >
              {t("Surrender")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GameInfoDialog;
