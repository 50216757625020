import React from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import Close Icon
import SkipNextIcon from "@mui/icons-material/SkipNext";
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
const MoveLog = ({
  isMobileView,
  t,
  getMoveHistory,
  getCurrentMoveIndex,
  setCurrentMoveIndex,
  game,
  setFen,
  updateCapturedPieces,
  disableClick,
  onClose, // Accept close function as a prop
}) => {
  let [localIndex, setLocalIndex] = React.useState(0);
  const moveHistory = getMoveHistory();
  const isMobile = window.innerWidth <= 600; // Check if the screen size is mobile
  //localIndex = getCurrentMoveIndex();
  
  const handleBackward = () => {
    if (localIndex > 0) {
      const newIndex = localIndex - 1;
      const previousFen = moveHistory[newIndex]?.fen;
      game.load(previousFen);
      setCurrentMoveIndex(newIndex);
      setFen(previousFen);
      updateCapturedPieces();
      setLocalIndex(newIndex);
    }
  };

  const handleForward = () => {
    if (localIndex < moveHistory.length - 1) {
      const newIndex = localIndex + 1;
      const nextFen = moveHistory[newIndex]?.fen;
      game.load(nextFen);
      setCurrentMoveIndex(newIndex);
      setFen(nextFen);
      updateCapturedPieces();
      setLocalIndex(newIndex);
    }
  };

  const handleGoToLast = () => {
    const lastIndex = moveHistory.length - 1;
    const lastFen = moveHistory[lastIndex]?.fen;
    game.load(lastFen);
    setCurrentMoveIndex(lastIndex);
    setFen(lastFen);
    updateCapturedPieces();
  };

  return (
    <Box
      sx={{
        
        position: "relative",
        border: isMobile === false ? "15px solid #ccc" : "5px solid #ccc", // Add border around the Stack
        borderRadius: isMobile === false ? "15px" : "15px", // Rounded corners
        boxShadow: isMobile === false ? "0 25px 25px black" : "0 25px 25px black",
        backgroundColor: isMobile === false ? "black" : "black", // Optional background color
        marginLeft: isMobileView ? "0" : "0px",
        marginTop: isMobileView ? "10px" : "10px",
        textAlign: "left",
        width: isMobileView ? "100%" : "330px",
        height: isMobileView ? "50%":"540px",
        
        background: "#f9f9f9",
                
        display: "flex",
        flexDirection: "column",
        padding: "7px"
      }}
    >
       {/* Close Button */}
       <IconButton
        onClick={onClose} // Close function passed as a prop
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
          color: "#ff0000", // Red color for visibility
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          marginBottom: "10px",
          color: "#1976d2",
          fontWeight: "bold",
        }}
      >
        {t("Move Log")}
      </Typography>
      <TableContainer component={Paper} sx={{ flex: 1, overflowY: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>{t("ID")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("From")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("To")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("Color")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moveHistory.map((entry, index) => {
              const moveParts = entry.move
                ? [entry.move.slice(0, 2), entry.move.slice(3, 5)]
                : ["N/A", "N/A"];
              const isStartPosition = index === 0;
              return (
                <TableRow
                  key={index}
                  hover
                  selected={localIndex === index}
                  onClick={() => {
                    if (!disableClick) {
                      setCurrentMoveIndex(index);
                      const selectedFen = entry.fen;
                      game.load(selectedFen);
                      setFen(selectedFen);
                      updateCapturedPieces();
                      setLocalIndex(index);
                    }
                  }}
                  sx={{
                    cursor: disableClick ? "default" : "pointer",
                    backgroundColor: localIndex === index ? "blue" : "inherit", // Set blue if selected
                    "&:hover": {
                      backgroundColor: localIndex === index ? "blue" : "rgba(0, 0, 0, 0.08)", // Preserve hover effect
                    },
                    color: localIndex === index ? "white" : "inherit", // Optional: make text white when selected
                  }}
                >
                  <TableCell>
                    {!isStartPosition ? index : ""}
                  </TableCell>
                  <TableCell>{!isStartPosition ? moveParts[0] : t("N/A")}</TableCell>
                  <TableCell>{!isStartPosition ? moveParts[1] : t("N/A")}</TableCell>
                  <TableCell>
                    {isStartPosition
                      ? t("Start_Position")
                      : (entry.color === "w" || entry.color === "white")
                      ? t("White")
                      : t("Black")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          position: "sticky",
          bottom: "0px",
          background: "#fff",
          padding: "10px 0",
          display: "flex",
          justifyContent: "space-between",
          gap: "5px",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid #ccc",
        }}
      >
       {!disableClick && (<Button
          variant="contained"
          size="small"
          startIcon={<UndoIcon />}
          onClick={handleBackward}
          disabled={localIndex === 0}
          sx={{
            flex: 1,
            backgroundColor: "#1976d2",
            "&:disabled": {
              backgroundColor: "#ccc",
            },
          }}
        >
          {t("Back")}
        </Button>)}
        {!disableClick && (<Button
          variant="contained"
          size="small"
          endIcon={<RedoIcon />}
          onClick={handleForward}
          disabled={localIndex === moveHistory.length - 1}
          sx={{
            flex: 1,
            backgroundColor: "#1976d2",
            "&:disabled": {
              backgroundColor: "#ccc",
            },
          }}
        >
          {t("Forward")}
        </Button>)}
        {!disableClick && (<Button
          variant="contained"
          size="small"
          startIcon={<SkipNextIcon />}
          onClick={handleGoToLast}
          disabled={localIndex === moveHistory.length - 1}
          sx={{
            flex: 1,
            backgroundColor: "#1976d2",
            "&:disabled": {
              backgroundColor: "#ccc",
            },
          }}
        >
          {t("Last")}
        </Button>)}
      </Box>
    </Box>
  );
};

export default MoveLog;
