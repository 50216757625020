
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import { AppProvider } from "@toolpad/core/AppProvider";
import { SignInPage } from "@toolpad/core/SignInPage";
import { useTheme } from "@mui/material/styles";
import { signInWithFacebook } from "../authMethods/signInWithFacebook"; 
import { signInWithGoogle } from "../authMethods/signInWithGoogle"; 
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


let popupInstance = null; // To store the popup reference
// Define available providers
const providers = [
  { id: "google", name: "Google" },
  { id: "facebook", name: "Facebook" },
];

export default function OAuthSignInPage({ onBack }) {
  const theme = useTheme();
  const navigate = useNavigate(); // Hook for navigation
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(null);

  // Main sign-in function
  const signIn = async (provider) => {
    try {
      let user;
      setLoading(true);
      switch (provider.id) {
        case "google":
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            popupInstance = await signInWithPopup(auth, provider);
            console.log("User signed in:", popupInstance.user);
            setUser(popupInstance.user);
            console.log("Google sign-in successful!", popupInstance.user);
            // Close popup programmatically (in case it's still open)
            closePopup();
          break;
        case "facebook":
          user = await signInWithFacebook();
          console.log("Facebook sign-in successful!", user);
          break;
        default:
          console.error(`Sign in with ${provider.name} is not implemented`);
          return;
      }
      
      if (popupInstance.user) {
        // Redirect to the main page
        navigate("/game"); // Replace "/main" with your desired route
      }
    } catch (error) {
      console.error(`Error during ${provider.name} sign-in:`, error);
    }
      finally {
        setLoading(false);
        window.location.reload();
    }
  };

  const closePopup = () => {
    try{
        if (popupInstance && !popupInstance.closed) {
            popupInstance.close();
            console.log("Popup closed programmatically.");
          } else {
            console.log("No active popup to close.");
        }
    }catch (error) {
        console.error(`Error during when clsoing the popup`, error);
      }
        finally {
            
      }
    
  };

  return (
    <AppProvider theme={theme}>
      <SignInPage signIn={signIn} providers={providers} />
    </AppProvider>
  );
}
