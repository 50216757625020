import React, { useState, useEffect, useRef, createRef, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";
import { sendMessage } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import Swal from 'sweetalert2'
import ControlPanel from "./ControlPanel";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Added import for translations
import GameEndInfo from "./GameEndInfo";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { useParams } from "react-router-dom";
import MoveLog from "./MoveLog";
import Clock from "./Clock"; // Import Clock component
import { useWebSocketManager } from "../services/useWebSocketManager";

const ResponsiveChessPage = ({
  generalMessage,
  updateBackground,
  viewer,
  userData,
}) => {
  const { invitationId, invitePlayerColor } = useParams(); // Extract invitationId and playerColor
  const [,] = useState(invitePlayerColor); // Use playerColor from URL
  const { t } = useTranslation(); // Initialize translations
 
  const navigate = useNavigate();
  const [game] = useState(new Chess());
  const [fen, setFen] = useState(game.fen());
  const [playerColor, setPlayerColor] = useState("white");
  const [gameId, setGameId] = useState(null);
  const [, setViewerFromServer] = useState(false);
  const [, setShowBlink] = useState(false);
  const [movesGuesses, setMovesGuesses] = useState(null);
  const [totalMoves, setTotalMoves] = useState(0);
  const [totalGuesses, setTotalGuesses] = useState(0);
  const [playerTime, setPlayerTime] = useState(0);
  const [opponentTime, setOpponentTime] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [bestMove, setBestMove] = useState(null);
  const [capturedWhite, setCapturedWhite] = useState([]);
  const [capturedBlack, setCapturedBlack] = useState([]);
  const [generalMessageTeamStatus, setGeneralMessageTeamStatus] =
    useState(null);
  const stockfishRef = useRef(null);
  const [, setGeneralMessageInit] = useState(null);
  const [aiLevel, setAiLevel] = useState(-1);
  const [aiMoveTimeout, setAiMoveTimeout] = useState(true);
  const [gameInfo, setGameInfo] = useState(null);
  const [showGameEnd, setShowGameEnd] = useState(false);
  const [lastAIMove, setLastAIMove] = useState(null);
  const location = useLocation();
  const generalMessageViaState = location.state?.generalMessage;
  const desktopScreen = "500";
  const mobileScreen = "350";
  const { gameData } = useWebSocketManager();
  const [, setUserDataFromServer] = useState(false);
  const [showViewerDialog, setShowViewerDialog] = useState(false); // State for the dialog
  const [isViewer, setIsViewer] = useState(false); // Track if the user chose to be a viewer
  const [openOfferDrawDialog, setOpenOfferDrawDialog] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [dontShowEmojiAgain, setDontShowEmojiAgain] = useState(false);
  const [offerDrawMessage, setOfferDrawMessage] = useState("");

  const ref = createRef(null);
  const [, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });
  const [openEmojiDialog, setOpenEmojiDialog] = useState(false);
  const [emojiMessage, setEmojiMessage] = useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [showGameHistory, setShowGameHistory] = useState(false);
  const scrollRef = useRef(null);
  const isMobile = window.innerWidth <= 600; // Check if the screen size is mobile
  const handleScrollUp = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };
  const analyzePosition = useCallback((fen) => {
    if (stockfishRef.current) {
      stockfishRef.current.postMessage(`position fen ${fen}`);
      stockfishRef.current.postMessage("go depth " + aiLevel);
    }
  }, [aiLevel]); // Remove `game` if not required

  function handleGameStart(generalMessage) {
    if (generalMessage) {
      if (generalMessage.messageType === "init_game") {
       
        localStorage.removeItem("dontShowOfferDrawDialog");
        localStorage.removeItem("dontShowEmoji");
        setGeneralMessageInit(generalMessage);
        setPlayerColor(generalMessage.color);
        
        let gameId =  generalMessage?.game?.gameId;
          if(gameId){
            localStorage.setItem("lastGameId", gameId); // Persist in local storage
            setGameId(generalMessage.game.gameId);
          }
        
        setAiLevel(generalMessage.gameInfo.aiLevel);
        setAiMoveTimeout(generalMessage.game.aiAsHuman);
        const initialFen = generalMessage.game.initialFen || game.fen(); // Fallback to default
        const fullFen = `${initialFen}`;
        game.load(fullFen);
        setFen(game.fen());

        analyzePosition(game.fen());

        console.log("Game initialized with FEN:", fullFen);
        console.log("Player color received:", generalMessage.color);
        console.log("Game ID updated to:", generalMessage.game.gameId);
      }

      if (generalMessage.messageType === "server_move" &&
        generalMessage.move.commandFen) {
        const actualFromToArray = [
          generalMessage.move.commandMove.slice(0, 2),
          generalMessage.move.commandMove.slice(2),
        ];

        let move = {};
        move.from = actualFromToArray[0];
        move.to = actualFromToArray[1];
        move.color = generalMessage.move.color;
        move.promotion = "q";
        // try {
        //     move = game.move({
        //     from: actualFromToArray[0],
        //     to: actualFromToArray[1],
        //     promotion: "q",
        //   });
        //   if (!move) return false;
        // } catch (error) {
        //   return false;
        // }
        //updateHistory(move);
        game.load(generalMessage.move.commandFen);

        analyzePosition(game.fen());
        setFen(game.fen());
        updateCapturedPieces();

        if(generalMessage?.user.userType === "AI"){
          return;
        }

        const newHistory = [
          ...getMoveHistory().slice(0, getCurrentMoveIndex() + 1),
          {
            fen: game.fen(),
            move: move.from + "-" + move.to,
            color: move.color,
          },
        ];
        setMoveHistory(newHistory);
        setCurrentMoveIndex(newHistory.length - 1);
      }

      if (generalMessage.messageType === "team_member_move") {
        setGeneralMessageTeamStatus(generalMessage);
      }

      if (generalMessage.messageType === "game_fen") {
        setFen(generalMessage.move.commandFen);
      }

      if (generalMessage.messageType === "game_over") {
        setGameInfo(generalMessage.gameInfo);
        setGameOver(true);
        setShowGameEnd(true);
        handleScrollUp();

        setShowBlink(true);

        setTimeout(() => {
          setShowBlink(false);
        }, 3000);
      }

      if (generalMessage?.messageType === "update_time") {
        let lastGameId = localStorage.getItem("lastGameId");
        if(generalMessage.game?.gameId === lastGameId){
          setGeneralMessageTeamStatus(generalMessage);
          if (playerColor === generalMessage.color) {
            setPlayerTime(generalMessage.move.currentSeconds);
            setOpponentTime(generalMessage.move.opponentSeconds);

            if(generalMessage.move.totalGuesses > 0 && generalMessage.move.totalMoves > 0){
              setTotalMoves(generalMessage.move.totalMoves);
              setTotalGuesses();  
              let mg = generalMessage.move.totalGuesses; //+ "/" + generalMessage.move.totalMoves;
              setMovesGuesses(mg); 
            }
            

          } else {
            setPlayerTime(generalMessage.move.opponentSeconds);
            setOpponentTime(generalMessage.move.currentSeconds);

            if(generalMessage.move.totalGuesses > 0 && generalMessage.move.totalMoves > 0){
              setTotalMoves(generalMessage.move.totalMoves);
              setTotalGuesses();  
              let mg = generalMessage.move.totalGuesses; // + "/" + generalMessage.move.totalMoves;
              setMovesGuesses(mg); 
            }
          }
        }
        else{
          console.error("skipped time");
        }
      }

      if (generalMessage.messageType === "offer_draw_request") {
        const dontShow = localStorage.getItem("dontShowOfferDrawDialog");
        if (!dontShow) {
          setOfferDrawMessage(t("Would you like to accept the draw offer?"));
          setOpenOfferDrawDialog(true);
        }
      }

      if (generalMessage.messageType === "offer_draw_reject") {
        
        Swal.fire({
          title: t("The draw offer was rejected"),
          icon: "error",
          draggable: true,
          confirmButtonText: t("OK"),
        });
      }

      if (generalMessage.messageType === "emoji") {
        const dontShowEmoji = localStorage.getItem("dontShowEmoji");
        if (!dontShowEmoji) {
          setEmojiMessage(generalMessage.content);
          setOpenEmojiDialog(true);
        }
      }
    }
  }

  useEffect(() => {
    if (gameData) {
      console.log(gameData);
    }
  
  }, [gameData]);

  useEffect(() => {
    if (generalMessageViaState) {
      if(generalMessageViaState.messageType === "init_game"){
        handleGameStart(generalMessageViaState);
        setGeneralMessageTeamStatus(generalMessageViaState);
      }
      else if(generalMessageViaState.messageType === "continue_game"){
        setGameId(generalMessageViaState.gameInfo.gameId);
        
        const initialFen = generalMessageViaState.game.currentFen;
        const fullFen = `${initialFen}`;
        game.load(fullFen);
        setFen(game.fen());
        analyzePosition(game.fen());
        setGeneralMessageTeamStatus(generalMessageViaState);
        setPlayerColor(generalMessageViaState.gameInfo.color);
        localStorage.removeItem("uncompleted_game", null); 
      }
    }
  // eslint-disable-next-line no-use-before-define
  }, [analyzePosition, generalMessageViaState]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault(); // refreshing or click back the page
      event.returnValue = ""; // Required for Chrome, shows a warning message
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "game_session_leaved";
      generalMessage.game.gameId = generalMessageViaState?.game.gameId;
      generalMessage.gameInfo.gameId = generalMessageViaState?.game.gameId;
      sendMessage(generalMessage);
    };

    window.addEventListener("beforeunload", handleBeforeUnload); // entering the page

    return () => {
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "game_session_leaved";
      generalMessage.game.gameId = generalMessageViaState?.game.gameId;
      generalMessage.gameInfo.gameId = generalMessageViaState?.game.gameId;
      sendMessage(generalMessage);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [generalMessageViaState?.game.gameId]);

  useEffect(() => {
    if(gameOver === false){
      document.body.style.overflow = "hidden"; // Disable scrolling
    }
    else{
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Enable scrolling on unmount
    };
  }, [isMobile, gameOver]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Scroll to top once the component is mounted
    }
  }, []);

  useEffect(() => {
    // Show the viewer dialog if the viewer prop is true
    if (viewer) {
      setShowViewerDialog(true);
    }
  }, [viewer]);

  useEffect(() => {
    setUserDataFromServer((prev) => userData || prev);
  }, [userData]);

  const getMoveHistory = useCallback(() =>
    JSON.parse(localStorage.getItem("moveHistory")) || [
      { fen: game.fen(), move: null },
    ], [game]);
  const setMoveHistory = (history) =>
    localStorage.setItem("moveHistory", JSON.stringify(history));
  const getCurrentMoveIndex = () =>
    parseInt(localStorage.getItem("currentMoveIndex"), 10) || 0;
  const setCurrentMoveIndex = (index) =>
    localStorage.setItem("currentMoveIndex", index);

  const updateCapturedPieces = useCallback(() => {
      const initialCounts = {
        w: { p: 8, r: 2, n: 2, b: 2, q: 1, k: 1 },
        b: { p: 8, r: 2, n: 2, b: 2, q: 1, k: 1 },
      };
  
      const currentCounts = {
        w: { p: 0, r: 0, n: 0, b: 0, q: 0, k: 0 },
        b: { p: 0, r: 0, n: 0, b: 0, q: 0, k: 0 },
      };
  
      // Count pieces currently on the board
      game
        .board()
        .flat()
        .forEach((square) => {
          if (square) {
            currentCounts[square.color][square.type]++;
          }
        });
  
      const newWhiteCaptured = [];
      const newBlackCaptured = [];
  
      // Importance mapping for sorting pieces (lower is more important)
      const pieceImportance = { k: 1, q: 2, r: 3, b: 4, n: 5, p: 6 };
  
      // Calculate captured pieces
      Object.keys(initialCounts.w).forEach((piece) => {
        const missingWhitePieces =
          initialCounts.w[piece] - currentCounts.w[piece];
        const missingBlackPieces =
          initialCounts.b[piece] - currentCounts.b[piece];
  
        if (missingWhitePieces > 0)
          newBlackCaptured.push(...Array(missingWhitePieces).fill(piece));
        if (missingBlackPieces > 0)
          newWhiteCaptured.push(...Array(missingBlackPieces).fill(piece));
      });
  
      // Sort captured pieces based on their importance
      const sortedWhiteCaptured = newWhiteCaptured.sort(
        (a, b) => pieceImportance[a] - pieceImportance[b]
      );
      const sortedBlackCaptured = newBlackCaptured.sort(
        (a, b) => pieceImportance[a] - pieceImportance[b]
      );
  
      // Update state with sorted captured pieces
      setCapturedWhite(sortedWhiteCaptured);
      setCapturedBlack(sortedBlackCaptured);
    }, [game]);

  useEffect(() => {
    if (localStorage.getItem("moveHistory")) {
      setMoveHistory([{ fen: game.fen(), move: null }]);
    }
    if (localStorage.getItem("currentMoveIndex")) {
      setCurrentMoveIndex(0);
    }

    const history = getMoveHistory();
    const index = getCurrentMoveIndex();
    setFen(history[index].fen);
    game.load(history[index].fen);
    updateCapturedPieces();
  }, [game, getMoveHistory, updateCapturedPieces]);

  const handleOfferDrawClose = () => {
    setOpenOfferDrawDialog(false);
  };

  const handleOfferDrawConfirm = () => {
    // Logic to accept the draw offer
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "offer_draw_accept";
    generalMessage.game.gameId = generalMessageViaState?.game.gameId;
    generalMessage.gameInfo.gameId = generalMessageViaState?.game.gameId;
    sendMessage(generalMessage);
    setOpenOfferDrawDialog(false);
  };

  const handleOfferDrawReject = () => {
    // Logic to reject the draw offer
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "offer_draw_reject";
    generalMessage.game.gameId = generalMessageViaState?.game.gameId;
    generalMessage.gameInfo.gameId = generalMessageViaState?.game.gameId;
    sendMessage(generalMessage);
    setOpenOfferDrawDialog(false);
  };

  const handleDontShowAgainChange = (event) => {
    setDontShowAgain(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("dontShowOfferDrawDialog", "true");
    } else {
      localStorage.removeItem("dontShowOfferDrawDialog");
    }
  };

  const handleDontShowEmojiAgainChange = (event) => {
    setDontShowEmojiAgain(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("dontShowEmoji", "true");
    } else {
      localStorage.removeItem("dontShowEmoji");
    }
  };

  const handleEmojiDialogClose = () => {
    setOpenEmojiDialog(false);
  };

  const handleJoinAsViewer = () => {
    setIsViewer(true); // Mark the user as a viewer
    setShowViewerDialog(false); // Close the dialog
    const inviteMessage = { ...generalMessageTemplate };
    inviteMessage.messageType = "invited_viewer";
    inviteMessage.gameInfo.gameId = invitationId;
    inviteMessage.color = invitePlayerColor;

    sendMessage(inviteMessage); // Notify the server
  };

  const handleDeclineViewer = () => {
    setIsViewer(false);
    setShowViewerDialog(false);
    navigate("/main"); // Navigate away if the user declines
  };

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  useEffect(() => {
    updateBackground({
      image: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415495/Pictures/cyume5jns2oovggj8z4g.png"
    });

    const isLocalhost = window.location.hostname === "localhost";

    stockfishRef.current = new Worker(
      isLocalhost
        ? `${process.env.PUBLIC_URL}/stockfish.wasm.js`
        : `${process.env.PUBLIC_URL}/stockfish.wasm.js`//"https://lichess1.org/stockfish/stockfish.wasm.js"
    );

    stockfishRef.current.postMessage("uci");     // ✅ INIT
    stockfishRef.current.postMessage("isready"); // ✅ Wait for readiness

    stockfishRef.current.onmessage = (event) => {
      const message = event.data;

      if (message.startsWith("bestmove")) {
        const bestMove = message.split(" ")[1];
        setBestMove(bestMove);

        // If AI needs to play
        if (
          generalMessageViaState?.playAgainstAI &&
          game.turn() !== playerColor[0] // AI's turn
        ) {
          executeAIMove(bestMove);
        }
      }
    };

    // Trigger AI's move if AI starts as white
    if (generalMessageViaState?.playAgainstAI && playerColor === "black") {
      analyzePosition(game.fen());
    }

    return () => {
      updateBackground({
        image: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415495/Pictures/cyume5jns2oovggj8z4g.png",
        size: "cover",
        position: "center",
        repeat: "no-repeat",
        attachment: "fixed", // Ensures background stays fixed during scrolling
      });

      if (stockfishRef.current) {
        stockfishRef.current.terminate();
      }
    };
  }, [updateBackground, generalMessageViaState, playerColor, analyzePosition]);

  useEffect(() => {
    if (invitationId) {
      setGameId(invitationId);
      setPlayerColor(invitePlayerColor);
    }
  }, [invitationId, gameId, invitePlayerColor]);

  useEffect(() => {
    if (viewer === true) {
      setViewerFromServer(viewer);
    }
  }, [viewer]);

  useEffect(() => {
    handleGameStart(generalMessage);

    
  }, [generalMessage]);

  

  const handleMove = (sourceSquare, targetSquare) => {
    if (invitationId) {
      return;
    }
    if (gameOver === true) {
      return;
    }
    if (
      (playerColor === "white" && game.turn() !== "w") ||
      (playerColor === "black" && game.turn() !== "b")
    ) {
      console.warn("It's not your turn!");
      return false;
    }

    let move;
    try {
      move = game.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q",
      });
      if (!move) return false;
    } catch (error) {
      return false;
    }

    //updateHistory(move);

    setFen(game.fen());
    updateCapturedPieces();
    analyzePosition(game.fen());
    let lastGameId = localStorage.getItem("lastGameId");
    let gameStatus = checkGameOver();
    const generalMessageToSend = { ...generalMessageTemplate };
    generalMessageToSend.move.userMove = sourceSquare + targetSquare;
    generalMessageToSend.move.bestMove = bestMove;
    generalMessageToSend.move.userFen = game.fen();
    generalMessageToSend.game.gameId = lastGameId;
    generalMessageToSend.move.color = playerColor;
    generalMessageToSend.messageType = "user_move";
    generalMessageToSend.color = playerColor;
    generalMessageToSend.game.gameStatus = gameStatus;
    generalMessageToSend.move.gameStatus = gameStatus;
    generalMessageToSend.user.userType = "Human";

    // Send position to Stockfish for analysis
    //analyzePosition(game.fen()); - to analyze the opponent best move

    sendMessage(generalMessageToSend);

    return true;
  };

  const executeAIMove = (bestMove) => {
    if (gameOver === true) {
      return;
    }
    if (!bestMove) return;

    let randomDelay = 1000;

    if (aiMoveTimeout === true) {
      randomDelay = Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000;
    }

    setTimeout(() => {
      const sourceSquare = bestMove.substring(0, 2);
      const targetSquare = bestMove.substring(2, 4);

      let move;
      try {
        move = game.move({
          from: sourceSquare,
          to: targetSquare,
          promotion: "q",
        });
      } catch (error) {}

      // Update captured pieces
      if (move && move.captured) {
        if (move.color === "w") {
          setCapturedBlack((prev) => [...prev, move.captured]);
        } else {
          setCapturedWhite((prev) => [...prev, move.captured]);
        }
      }

      //updateHistory(move);

      setFen(game.fen());
      updateCapturedPieces();
      analyzePosition(game.fen());
      let gameStatus = checkGameOver();

      const generalMessageToSend = { ...generalMessageTemplate };
      generalMessageToSend.move.userMove = sourceSquare + targetSquare;
      if (lastAIMove === generalMessageToSend.move.userMove) {
        return;
      }
      setLastAIMove(generalMessageToSend.move.userMove);
      generalMessageToSend.move.bestMove = bestMove;
      generalMessageToSend.move.userFen = game.fen();
      let lastGameId = localStorage.getItem("lastGameId");
      generalMessageToSend.game.gameId = lastGameId;
      generalMessageToSend.move.color =
        playerColor === "white" ? "black" : "white";
      generalMessageToSend.messageType = "user_move";
      generalMessageToSend.color = generalMessageToSend.move.color;
      generalMessageToSend.game.gameStatus = gameStatus;
      generalMessageToSend.move.gameStatus = gameStatus;
      generalMessageToSend.user.userType = "AI";

      // Send position to Stockfish for analysis
      //analyzePosition(game.fen()); - to analyze the opponent best move

      sendMessage(generalMessageToSend);
    }, randomDelay);
  };

  const checkGameOver = () => {
    let ret = "in_progress";
    if (game.isCheckmate()) {
      const winner = game.turn() === "w" ? "Black" : "White";
      if (winner === "White") {
        ret = "white_won_by_mat_black";
      } else {
        ret = "black_won_by_mat_white";
      }
    } else if (game.isStalemate()) {
      ret = "stalemate";
    } else if (game.isThreefoldRepetition()) {
      ret = "threefold_repetition";
    } else if (game.isInsufficientMaterial()) {
      ret = "insufficient_material";
    } else if (game.isCheck()) {
      ret = "check";
    } else if (game.isDraw()) {
      ret = "draw";
    }

    return ret;
  };

  const renderCapturedPieces = (pieces, color, position) => (
    <div
      style={{
        position: "relative", // Use relative positioning to allow absolute children
        width: "100%", // Set the container width to 100%
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Horizontally center the content
        alignItems: "center", // Vertically center the content
        padding: "5px",
      }}
    >
      {/* Background Image */}
      <div
        // style={{
        //   position: "absolute", // Position absolute for the image
        //   width: "120px",
        //   height: "120px",
        //   //backgroundImage: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743415495/Pictures/cyume5jns2oovggj8z4g.png"
        //   backgroundSize: "cover", // Ensure the image covers the container
        //   backgroundRepeat: "no-repeat", // Prevent repetition
        //   backgroundPosition: "center", // Center the image within the container
        //   zIndex: 0, // Place it behind all other elements
        // }}
      />

      {/* Captured Pieces */}
      <div
        style={{
          position: "relative",
          display: "flex",
          top: position === "top" ? (isMobile ? -18 : 1) : 10,
          // left: position === "top" ? "-194%" : "-110%",
          gap: "5px",
          zIndex: 10, // Ensure pieces are above the background
        }}
      >
        {pieces && pieces.length > 0 ? (
          pieces.map((piece, index) => (
            piece && (<img
              key={index}
              src={`https://res.cloudinary.com/dnlgtek2a/image/upload/v1743416894/Figures/${piece}_${color}.svg`}
              alt={`${color} ${piece}`}
              style={{ width: "30px", height: "30px" }}
            />)
          ))
        ) : (
          <span style={{ color: color === "w" ? "white" : "black" }}>
            {/* Optional placeholder */}
          </span>
        )}
      </div>
    </div>
  );

  const closeGameEnd = () => {
    setShowGameEnd(false);
  };

  const exitToMainNenu = () => {
    setShowGameEnd(false);
    navigate("/main");
  };

  const playSameGame = () => {
    setShowGameEnd(false);

    generalMessage.messageType = "new_game_request";
    navigate("/main", { state: { generalMessage } });
  };

  const rematch = () => {
    setShowGameEnd(false);

    generalMessage.messageType = "new_game_request";
    navigate("/main");
  };

  const captureBoard = () => {
    downloadScreenshot();
  };

  const handleAccordionChange = (isExpanded) => {
    setIsAccordionOpen(isExpanded);
  };
  const handleGameHistoryChange = (isOpen) => {
    setShowGameHistory(isOpen);
  };

  return (
    <div>
      {/* MUI Dialog for Joining as Viewer */}
      <Dialog
        open={showViewerDialog}
        onClose={handleDeclineViewer}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>{t("Join as Viewer?")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("Would you like to join this game as a viewer?")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeclineViewer} color="primary">
            {t("No")}
          </Button>
          <Button
            onClick={handleJoinAsViewer}
            color="secondary"
            variant="contained"
          >
            {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* MUI Dialog for Emoji Message */}
      <Dialog open={openEmojiDialog} onClose={handleEmojiDialogClose}>
        <DialogTitle>{t("Emoji Message")}</DialogTitle>
        <DialogContent>
          <Typography>{emojiMessage}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowEmojiAgain}
                onChange={handleDontShowEmojiAgainChange}
              />
            }
            label={t("Don't show again")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmojiDialogClose} color="primary">
            {t("OK")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* MUI Dialog for Offering a Draw */}
      <Dialog open={openOfferDrawDialog} onClose={handleOfferDrawClose}>
        <DialogTitle>{t("Draw Offer")}</DialogTitle>
        <DialogContent>
          <Typography>{offerDrawMessage}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={handleDontShowAgainChange}
                name="dontShowAgain"
              />
            }
            label={t("Don't show this dialog again")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOfferDrawReject} color="primary">
            {t("Reject")}
          </Button>
          <Button
            onClick={handleOfferDrawConfirm}
            color="secondary"
            variant="contained"
          >
            {t("Accept")}
          </Button>
        </DialogActions>
      </Dialog>

      <div
        style={{
          display: "flex",
          flexDirection: "column", // Stack chessboard and avatar vertically
          alignItems: "center", // Center horizontally
          margin: "0 auto", // Center horizontally on the page
          position: "relative",
          paddingBottom: "20px", // Extra space for the avatars
          width:
            window.innerWidth > 600
              ? `${desktopScreen}px`
              : `${mobileScreen}px`,
        }}
      >
        {gameOver === true && showGameEnd === true && (
          <div style={{ marginTop: isMobile === false ? "20px" : "10px", marginBottom: "40px" }}>
            <GameEndInfo
              gameInfoFromServer={gameInfo}
              onRematch={rematch}
              onPlaySameGame={playSameGame}
              onExitToMainMenu={exitToMainNenu}
              onSaveGame={() => console.log("Save Game clicked")}
              onShareGameHistory={() =>
                console.log("Share Game History clicked")
              }
              onShareBoardScreenshot={captureBoard}
              onClose={closeGameEnd}
              isViewer={isViewer}
            />
          </div>
        )}

        {!gameOver && opponentTime > 0 && (
          <div style={{ marginTop: isMobile === false ? "5px" : "5px", marginBottom: isMobile === false ? "2px" : "22px" }}>
          <Clock
            path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743417975/Lottie/timer.json"}
            time={opponentTime} // Dynamically pass the opponent's time
            width="50px"
                height="50px"
                marginRight="-5px"
            loop
            autoplay={
              !(
                (playerColor === "white" && game.turn() === "w") ||
                (playerColor === "black" && game.turn() === "b")
              )
            }
            avatars={
              generalMessageTeamStatus?.game[
                playerColor === "white" ? "blackTeam" : "whiteTeam"
              ] || []
            }
            position="top"
            userData={userData}
          />
          </div>
        )}

        {/* Captured Pieces - Top */}
        {!gameOver && (
          <div>
            {playerColor === "black"
              ? renderCapturedPieces(capturedWhite, "b", "top")
              : renderCapturedPieces(capturedBlack, "w", "top")}
          </div>
        )}

        <Stack direction="row" spacing={2}>
          {/* Chessboard */}
          <div ref={ref}>
            {" "}
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                
                border: isMobile === false ? "15px solid #ccc" : "5px solid #ccc", // Add border around the Stack
                borderRadius: isMobile === false ? "15px" : "15px", // Rounded corners
                boxShadow: isMobile === false ? "0 25px 25px black" : "0 25px 25px black",
                backgroundColor: isMobile === false ? "black" : "black", // Optional background color
                padding: isMobile === false ? "5px" : "5px", // Adjust as needed for left padding
                position: "relative",
                zIndex: 10,
                marginTop: isMobile === false ? "0px" : "-25px",
              }}
            >
              <Chessboard
                position={fen}
                onPieceDrop={handleMove}
                boardOrientation={playerColor}
                boardWidth={
                  window.innerWidth > 600 ? desktopScreen : mobileScreen
                }
              />
            </div>
          </div>
          {isMobile === false && showGameHistory === true && (
            <MoveLog
              isMobileView={isMobile}
              t={t}
              getMoveHistory={getMoveHistory}
              getCurrentMoveIndex={() => getCurrentMoveIndex}
              setCurrentMoveIndex={setCurrentMoveIndex}
              game={game}
              setFen={setFen}
              updateCapturedPieces={() => {
                const capturedPieces = game
                  .board()
                  .flat()
                  .filter((piece) => piece && piece.color !== playerColor[0]);
                setCapturedWhite(
                  capturedPieces.filter((piece) => piece.color === "w")
                );
                setCapturedBlack(
                  capturedPieces.filter((piece) => piece.color === "b")
                );
              }}
              disableClick={!gameOver}
              onClose={() => {handleGameHistoryChange(false)}}
            />
          )}
        </Stack>

        <Stack direction="column" spacing={isMobile === false ? 0 : -2}>
          {/* Captured Pieces - Bottom */}
          {gameOver === false && (
            <div>
              {playerColor === "black"
                ? renderCapturedPieces(capturedBlack, "w", "bottom")
                : renderCapturedPieces(capturedWhite, "b", "bottom")}
            </div>
          )}

          <div style={{ marginTop: isMobile === false ? "5px" : "2px", marginBottom: isMobile === false ? "2px" : "-50px" }}>
            {!isAccordionOpen && !gameOver && playerTime > 0 && (
              <Clock
                path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743417975/Lottie/timer.json"}
                time={playerTime} // Dynamically pass the opponent's time
                width="50px"
                height="50px"
                //marginRight="-5px"
                loop
                autoplay={
                  (playerColor === "white" && game.turn() === "w") ||
                  (playerColor === "black" && game.turn() === "b")
                }
                avatars={
                  generalMessageTeamStatus?.game[
                    playerColor === "white" ? "whiteTeam" : "blackTeam"
                  ] || []
                }
                position="bottom"
                userData={userData}
                totalMoves={totalMoves}
                totalGuesses={totalGuesses}
                movesGuesses={movesGuesses}
              />
            )}
          </div>
        </Stack>

        <div
          style={{
            maxWidth: isMobile ? `${mobileScreen}px` : `${desktopScreen}px`, // Match chessboard width
            marginTop: gameOver ? isMobile ? "18px" : "18px" : "55px", // Default margin
            marginLeft: "auto", // Center horizontally
            marginRight: "auto", // Center horizontally
          }}
        >
          <ControlPanel
            boardWidth={window.innerWidth > 600 ? desktopScreen : mobileScreen}
            playerColorFromServer={playerColor}
            gameIdFromServer={gameId}
            onCaptureBoard={captureBoard}
            setIsViewer={isViewer}
            gameOver={gameOver}
            onAccordionChange={handleAccordionChange}
            onGameHistoryChange={handleGameHistoryChange}
            getMoveHistory={getMoveHistory}
            aiLevel={aiLevel}
            gameInfo={gameInfo}
          ></ControlPanel>
        </div>

        {/* Move Log */}
        {isMobile === true && showGameHistory === true && (
          <div>
            <MoveLog
              isMobileView={window.innerWidth <= 600}
              t={t}
              getMoveHistory={getMoveHistory}
              getCurrentMoveIndex={() => getCurrentMoveIndex}
              setCurrentMoveIndex={setCurrentMoveIndex}
              game={game}
              setFen={setFen}
              updateCapturedPieces={() => {
                updateCapturedPieces();
              }}
              disableClick={!gameOver}
              onClose={() => {handleGameHistoryChange(false)}}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResponsiveChessPage;
