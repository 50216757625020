import React from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";

const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };
  
  const DemocraticChessOverview = ({userData}) => {
    const { t } = useTranslation();

    React.useEffect(() => {
        if (userData) {
          console.log(userData);
        }
      }, [userData]);
  
    return (
      <div className="max-w-5xl mx-auto p-8">
        <motion.h1 
          className="text-4xl font-extrabold text-center mb-8 text-gray-900"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          {t("overview.title")}
        </motion.h1>
        
        <motion.div initial="hidden" animate="visible" variants={fadeIn}>
          <Card className="mb-8 shadow-xl rounded-3xl bg-white">
            <CardContent className="p-8 text-lg text-gray-700">
              {t("overview.description")}
            </CardContent>
          </Card>
        </motion.div>
  
        <motion.h2 
          className="text-3xl font-semibold mb-6 text-gray-800"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          {t("howItWorks.title")}
        </motion.h2>
  
        {["teamGameplay", "voteBasedDecision", "systemCoordination"].map((key, index) => (
          <motion.div key={key} initial="hidden" animate="visible" variants={fadeIn} transition={{ delay: index * 0.2 }}>
            <Card className="mb-6 shadow-lg rounded-3xl bg-white">
              <CardContent className="p-6">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{t(`howItWorks.${key}.title`)}</h3>
                <p className="text-gray-700">{t(`howItWorks.${key}.description`)}</p>
              </CardContent>
            </Card>
          </motion.div>
        ))}
  
        <motion.h2 
          className="text-3xl font-semibold mb-6 text-gray-800"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          {t("keyFeatures.title")}
        </motion.h2>
        
        <motion.div initial="hidden" animate="visible" variants={fadeIn}>
          <Card className="mb-8 shadow-xl rounded-3xl bg-white">
            <CardContent className="p-8">
              <ul className="list-disc pl-6 space-y-3 text-gray-700">
                <li className="text-lg">{t("keyFeatures.onlinePlay")}</li>
                <li className="text-lg">{t("keyFeatures.fairVoting")}</li>
                <li className="text-lg">{t("keyFeatures.strategicDepth")}</li>
              </ul>
            </CardContent>
          </Card>
        </motion.div>
        
        <motion.h2 
          className="text-3xl font-semibold mb-6 text-gray-800"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          {t("whyUnique.title")}
        </motion.h2>
  
        <motion.div initial="hidden" animate="visible" variants={fadeIn}>
          <Card className="mb-8 shadow-xl rounded-3xl bg-white">
            <CardContent className="p-8 text-gray-700">
              {t("whyUnique.description")}
            </CardContent>
          </Card>
        </motion.div>
        
        <div className="text-center mt-8">
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button className="px-6 py-3 text-lg font-semibold bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700">
              {t("cta.learnMore")}
            </Button>
          </motion.div>
        </div>
      </div>
    );
  };
  
  export default DemocraticChessOverview;
  