export const generalMessageTemplate = {
    content: null,
    game: {
     
      secondsToWaitForUsers: null,
      startDate: null,
      endDate: null,
      gameId: null,
      gameStatus: null,
    
      maxTeamMembers: null,
      minTeamMembers: null,
      whiteMoves: null,
      blackMoves: null,
      currentTurn: null,
      initialFen: null,
      currentFen: null,
      whiteTeam: [],
      blackTeam: [],
      all: [],
     
    },
    move: {
      
      color: null,
      userMove: null,
      userFen: null,
      commandMove: null,
      commandFen: null,
      bestMove: null,
      bestFen: null,
      secondsForMove: null,
    },
    messageType: null,
    sessionId: null,
    user: {
      id: null,
      userName: null,
      firstName: null,
      secondName: null,
      userStatistics: {
        totalGames: null,
        totalWins: null,
        totalLose: null,
        totalDraw: null,
        currentScore: null,
        bestScore: null,
        league: null,
        rating: null,
      },
      userType: "Human",
      socialUser: null,
      browserId: null,
    },
    gameInfo: {
      
      pointsWin: null,
      pointsLose: null,
      pointsDraw: null,
      gameTimeType: null,
      secondsBasedOnGameTimeType: null,
      gameTeamType: null,
      userType: null,
      aiLevel: null,
      color: null,
      winnerColor: null,
      gameName: null,
      gameStatus: null,
      gameHistory: null,
    },
    color: null,
    payment: {
      amount: null,
      quanity: null,
      price: null,
      paymentDate: null,
      transactionId: null,
      provider: null,
      currency: null,
      token: null,
      productType: null,
      productId: null,
      productName: null,
      paymentMethodNonce: null,
    }
    
  };