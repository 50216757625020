import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";
import { useWebSocket } from "../services/WebSocketProvider";
import { sendMessage } from "../services/websocketManager";
import { useTranslation } from "react-i18next";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import useOpponentsList from "../entities/useOpponentsList.js";


const GlobalDialog = () => {
  const { t } = useTranslation();
  const opponents = useOpponentsList();
  const { dialogData, setDialogData } = useWebSocket();

  const handleAccept = () => {
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "invite_to_game_accept";
    generalMessage.game.gameId = message.game.gameId;
    generalMessage.gameInfo = message.gameInfo;
    sendMessage(generalMessage);
    handleClose();
  };

  const handleReject = () => {
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "invite_to_game_reject";
    generalMessage.game.gameId = message.game.gameId;
    sendMessage(generalMessage);
    handleClose();
  };

  if (!dialogData || dialogData.message.messageType !== "invite_to_game") {
    return null; // Only render the dialog for game invitations
  }
  
  const { title, content, message } = dialogData; // dialogData?.message

  const handleClose = () => {
    setDialogData(null); // Close dialog
  };

  const getOpponentByGameType = (t, gameType) => {
    if(!gameType){
        return;
    }
    const list = opponents;
    return list.find((opponent) => opponent.gameType === gameType) || null;
  }

  
  return (
    <Dialog open={!!dialogData} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>

     <DialogContent>
        <Typography variant="body1">{content}</Typography>
        <Box sx={{ marginTop: 2 }}>
          {/* <Typography variant="body2">
            <strong>Game ID:</strong> {gameInfo.gameId}
          </Typography> */}
          <Typography variant="body2">
            <strong>{t("Initiator")}:</strong> {message?.user.name}
          </Typography>
          <Typography variant="body2">
            <strong>{t("Game Mode")}:</strong> {getOpponentByGameType(t, message?.gameInfo.gameTeamType)?.name}
          </Typography>
          <Typography variant="body2">
            <strong>{t("Time_limit")}:</strong> {message?.gameInfo.gameTimeType}
          </Typography>
          {/* <Typography variant="body2">
            <strong>Start Time:</strong> {new Date(gameInfo.startTime).toLocaleString()}
          </Typography> */}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleReject} color="secondary">
          {t("Reject")}
        </Button>
        <Button onClick={handleAccept} color="primary" autoFocus>
          {t("Accept")}
        </Button>
      </DialogActions>

    </Dialog>
  );
};

export default GlobalDialog;
