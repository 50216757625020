import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import PurchaseCoins from "./PurchaseCoins"; // Import the new standalone component
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("your-stripe-public-key");

const NotEnoughCoinsDialog = ({ open, onClose, onWatchAd, neededCoins, actualCoins, buy=false, userData }) => {
  const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);
  const { t } = useTranslation();

  const[title, setTitle] = useState(t("Not Enough Coins"));

  const handleBuyCoinsClick = () => {
    setShowPurchaseOptions(true);
  };

   React.useEffect(() => {
        // Retrieve the last selected index from localStorage on component mount
        
        if (buy === false) {
            //console.log("not enough");
        }
        if (buy === true) {
          //console.log("buy");
          setTitle(t("Buy Coins"));
          handleBuyCoinsClick();
        }
      }, [buy, t]); 

      React.useEffect(() => {
        //console.log(userData);
      }, [userData]); 

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth sx={{
      zIndex: 3500}}>
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", marginTop: "20px", bgcolor: "gold" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        {buy === false && (<Typography variant="body1" sx={{ textAlign: "center", marginBottom: "10px" }}>
          {t("You don’t have enough coins to play the selected game")}
        </Typography>)}
        {buy === false && (<Typography variant="h6" sx={{ textAlign: "center", color: "red", fontWeight: "bold" }}>
          {t("Needed")}: {neededCoins} | {t("You Have")}: {actualCoins}
        </Typography>)}
        {!showPurchaseOptions ? (
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBuyCoinsClick}
              sx={{ fontWeight: "bold", padding: "10px 20px" }}
            >
              {t("Buy Coins")}
            </Button>
            {buy === false && (<Button
              variant="outlined"
              color="secondary"
              onClick={onWatchAd}
              sx={{ fontWeight: "bold", padding: "10px 20px" }}
            >
              {t("Watch Ad")}
            </Button>)}
          </Box>
        ) : (
          <Elements stripe={stripePromise}>
            <PurchaseCoins onClose={() => {setShowPurchaseOptions(false); onClose();}} enablePurchase={true} userData={userData}  />
          </Elements>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose} color="error" variant="text">
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotEnoughCoinsDialog;
