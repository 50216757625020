import React, { useState, useEffect} from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap styles are included

import useOpponentsList from "../entities/useOpponentsList";
import GameOpponentSelectionItem from "./GameOpponentSelectionItem"; // Import your component
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function OpponentSelectionCarousel({
  scaleConfig = { selected: 0.75, nonSelected: 0.5 },
  onOpponentSelected,
  onFriendSelected,
  setShowAppBar
}) {
  
  const opponents = useOpponentsList();
  const [index, setIndex] = useState(0); // Track active index of the carousel
  const [isCardOpen, setIsCardOpen] = useState(false); // Track "more info" card state
 

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    localStorage.setItem("lastSelectedOpponentIndex", selectedIndex); // Persist in local storage
    handleCarouselMove();
  };

  const handleCarouselMove = () => {
    if (isCardOpen) {
      setIsCardOpen(false); // Close the card when the carousel moves
    }
  };

  useEffect(() => {
    // Retrieve the last selected index from localStorage
    const lastSelectedIndex = localStorage.getItem("lastSelectedOpponentIndex");
    if (lastSelectedIndex !== null) {
      setIndex(parseInt(lastSelectedIndex, 10)); // Set the active index
    }
  }, []); // Run only on component mount

  useEffect(() => {
    setShowAppBar(false); 
    return () => {
      setShowAppBar(true); 
    };
  }, [setShowAppBar]);
  // ... rest of the component

  return (
    <div
      style={{
        position: "relative",
        pointerEvents: "none", // Prevent clicks on the entire container
      }}
    >
      {/* Carousel */}
      <Carousel
        prevIcon={
          <ChevronLeftIcon
            sx={{
              position: "absolute", // Position the arrow absolutely within the carousel
              top: "20%", // Center the arrow vertically
              transform: "translateY(-50%)", // Ensure it is perfectly centered
              fontSize: "4rem", // Adjust icon size
              color: "black", // Set icon color
              backgroundColor: "white", // Background color for better visibility
              borderRadius: "50%", // Make it circular
              padding: "5px", // Add some padding
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Add a shadow for better visibility
              cursor: "pointer", // Indicate clickability
              zIndex: 10, // Ensure it appears above other elements
              left: "10px", // Add consistent spacing from the left edge
              pointerEvents: "auto", // Ensure clicks work on the arrow
            }}
          />
        }
        nextIcon={
          <ChevronRightIcon
            sx={{
              position: "absolute", // Position the arrow absolutely within the carousel
              top: "20%", // Center the arrow vertically
              transform: "translateY(-50%)", // Ensure it is perfectly centered
              fontSize: "4rem", // Adjust icon size
              color: "black", // Set icon color
              backgroundColor: "white", // Background color for better visibility
              borderRadius: "50%", // Make it circular
              padding: "5px", // Add some padding
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Add a shadow for better visibility
              cursor: "pointer", // Indicate clickability
              zIndex: 10, // Ensure it appears above other elements
              right: "10px", // Add consistent spacing from the right edge
              pointerEvents: "auto", // Ensure clicks work on the arrow
            }}
          />
        }
        indicators={false}
        slide={false}
        fade={true}
        keyboard={true}
        interval={null}
        activeIndex={index}
        onSelect={handleSelect}
        //style={{ pointerEvents: "auto" }} // Ensure clicks work on the carousel itself
      >
        {opponents.map((item, levelIndex) => (
          <Carousel.Item key={levelIndex}>
            <div
              style={{
                padding: "10px",
                transform:
                  index === levelIndex
                    ? `scale(${scaleConfig.selected})`
                    : `scale(${scaleConfig.selected})`, // Scale based on configuration
                opacity: index === levelIndex ? 1 : 1, // Dim non-selected items
                borderRadius: "10px",
                overflow: "hidden",
                pointerEvents: "auto", // Ensure clicks work on this item
              }}
            >
              <GameOpponentSelectionItem
                
                onOpponentSelected={onOpponentSelected}
                onFriendSelected={onFriendSelected}
                game_type_id={item.gameTypeId}
                name={item.name}
                description={item.description}
                imagePath={item.imagePath}
                videoPath={item.videoPath}
                autoPlay={true}
                gameType={item.gameType}
                isCardOpen={isCardOpen}
                isSelected={index === levelIndex}
                onSelect={() => handleSelect(levelIndex)}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
