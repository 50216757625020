import { auth, facebookProvider } from "../authMethods/firebaseConfig"; // Adjust the path to your Firebase setup file
import { signInWithPopup } from "firebase/auth";

export const signInWithFacebook = async () => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    const user = result.user;
    console.log("Facebook sign-in successful!", user);
  } catch (error) {
    console.error("Error during Facebook sign-in:", error);
  }
};
