import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { addListener, removeListener } from "../services/websocketManager";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [dialogData, setDialogData] = useState(null);
  const [uncompletedGameWS, setUncompletedGameWS] = useState(null);
  const { t } = useTranslation();

  // Memoize handleWebSocketEvent to prevent unnecessary re-renders
  const handleWebSocketEvent = useCallback((event) => {
    if (event.type === "message") {
      if (event.message.messageType === "invite_to_game") {
        setDialogData((prev) => {
          // Only update if data actually changed
          if (JSON.stringify(prev) !== JSON.stringify(event.message)) {
            return {
              title: event.message.title || "Notification",
              content: event.message.content || "You have a new message!",
              message: event.message, // Pass the full message
            };
          }
          return prev;
        });
      } 
      else if (event.message.messageType === "reward") {
  setDialogData((prev) => {
    if (JSON.stringify(prev) !== JSON.stringify(event.message)) {
      // ✅ Show translated SweetAlert
      Swal.fire({
        title: t("Daily Reward!"),
        text: t("You just received") + " " + event.message.message +  " " + "free coins! Come back every day to claim your daily reward",
        icon: "success",
        confirmButtonText: t("Awesome!"),
      });

      return {
        title: event.message.title || "Notification",
        content: event.message.content || "You have a new message!",
        message: event.message,
      };
    }
    return prev;
  });
}
      else if (event.message.messageType === "uncompleted_game") {
        setUncompletedGameWS((prev) => {
          if (JSON.stringify(prev) !== JSON.stringify(event.message)) {
            return event.message;
          }
          return prev;
        });

        // Avoid unnecessary writes to localStorage
        const existingGame = localStorage.getItem("uncompleted_game");
        const newGame = JSON.stringify(event.message.gameInfo);
        if (existingGame !== newGame) {
          localStorage.setItem("uncompleted_game", newGame);
        }
      }
    }
  }, []);

  useEffect(() => {
    addListener(handleWebSocketEvent);
    return () => {
      removeListener(handleWebSocketEvent);
    };
  }, [handleWebSocketEvent]); // Dependency array ensures `handleWebSocketEvent` doesn't change

  return (
    <WebSocketContext.Provider value={{ dialogData, setDialogData }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
