import { useEffect, useState } from "react";
import { sendMessage, addListener, removeListener } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import { useTranslation } from "react-i18next";
const useLevelList = () => {
  const [levels, setlevels] = useState([]);
  const [language, setLanguage] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    // Function to handle incoming WebSocket messages
    const handleWebSocketMessage = (event) => {
      if (event.type === "message" && event.message.messageType === "game_levels") {
        const data = event.message.gameLevels
          ?.filter(level => level) // Remove undefined values
          .map((level) => {
            
            let cleanedImagePath = level.imagePath?.replaceAll("^\"|\"$", "");
            let cleanedVideoPath = level.videoPath?.replaceAll("^\"|\"$", "");
           
            return {
              name: level.name ? t(level.name) : "Unknown Level",
              description: level.description ? t(level.description) : "No description available",
              imagePath: cleanedImagePath,
              videoPath: cleanedVideoPath,
              gameLevelId: level?.gameLevelId || "0",
              levelTypeId: level?.levelTypeId || "0",
              win: level.win,
              lose: level.lose,
              draw: level.draw,
              winCoins: level.winCoins,
              loseCoins: level.loseCoins,
              drawCoins: level.drawCoins,
              active: level.active
            };
          });
  
          setlevels(data || []); // Ensure state is never undefined
          if(data){
            localStorage.setItem("levels", JSON.stringify(data));
          }
    }
  };

    // Subscribe to WebSocket events
    addListener(handleWebSocketMessage);

     // Request opponent list on language change or first load
    const currentLanguage = localStorage.getItem("i18nextLng"); // Detect current language
    let levels1 = localStorage.getItem("levels");
    
    if (currentLanguage !== language || !levels1) {
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "game_levels";
      sendMessage(generalMessage);
      setLanguage(currentLanguage); // Track language updates
      
      
    }

    // Cleanup WebSocket listener on component unmount
    return () => {
      removeListener(handleWebSocketMessage);
    };
  }, [language, levels, t]); // Ensure it updates when `t` changes

  return levels ;
};

export default useLevelList;