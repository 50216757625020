import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBuVL3m_UYXI159ZLU-p66QMAG3EiK_E-s",
    authDomain: "democraticchess.firebaseapp.com",
    projectId: "democraticchess",
    storageBucket: "democraticchess.firebasestorage.app",
    messagingSenderId: "610364184003",
    appId: "1:610364184003:web:1b5f2340f5f36d72c09976",
    measurementId: "G-3VWBBEQL6S"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();


export { auth, googleProvider, facebookProvider, signInWithPopup, firebaseConfig, storage  };