import * as React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { LottieAnimation } from "../lottie/LottieAnimation.js";
import { Box, Avatar, Badge } from "@mui/material";
import { sendMessage } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import Profile from "./Profile.js";

export default function Clock({
  time = 60,
  path = "",
  width = "150px",
  height = "150px",
  marginTop = "0px",
  marginRight = "0px",
  marginLeft = "0px",
  loop = true,
  autoplay = true,
  avatars,
  position,
  userData,
  totalMoves,
  totalGuesses,
  movesGuesses
}) {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 600;
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const remainingSeconds = (seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${remainingSeconds}`;
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedAvatar, setSelectedAvatar] = React.useState(null);

  const handleAvatarClick = (avatar) => {
    setSelectedAvatar(avatar);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedAvatar(null);
  };

  const handleAddToFavorite = () => {
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "add_to_favorite";
    generalMessage.user.userName = selectedAvatar.userName;
    sendMessage(generalMessage);
    handleDialogClose();
  };

  React.useEffect(() => {
    if (avatars?.length > 0) {
      avatars.forEach((avatar) => {});
    }
  }, [avatars]);

  return (
    <Box sx={{ flexGrow: 1, padding: 1, zIndex: 100 }}>
      <Stack
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{
          border: "4px solid #ccc",
          borderRadius: "15px",
          padding: "0px",
          boxShadow: "0 15px 15px black",
          zIndex: 20,
          position: "relative",
        }}
      >
        {avatars?.length === 1 && (
          <LottieAnimation
            path={path}
            width={width}
            height={height}
            marginTop={"5px"}
            marginRight={marginRight}
            marginLeft={marginLeft}
            autoplay={autoplay}
            loop={loop}
          />
        )}

        <div
          style={{
            marginTop: "-1px",
            fontSize: "22px",
            color: isMobile === false ? "black" : "blcak",
            fontWeight: "bold",
            textAlign: "center",
            padding: "3px",
            zIndex: 100,
            border: "4px solid #ccc",
            borderRadius: "15px",
            boxShadow: "0 15px 15px black",
          }}
        >
          {t("Your_Time")} {formatTime(time)}
        </div>
        {avatars?.map((avatar, index) => {
          const isCurrentUser = avatar?.name === userData?.name;
          return (
            <Badge
              key={index}
              badgeContent={
                avatar?.moveStatus === "waiting" ? "" : t(avatar?.moveStatus)
              }
              color={avatar?.moveStatus === "waiting" ? "error" : "primary"}
              sx={{
                zIndex: 100,
                "& .MuiBadge-badge": {
                  top: -5,
                  right: -5,
                },
              }}
            >
              <div style={{position: 'relative', display: 'inline-block'}}>
                <Avatar
                  alt={avatar?.name}
                  src={avatar?.url}
                  style={{
                    border: isCurrentUser ? "4px solid green" : "4px solid #ccc", // Increased border for current user
                    borderRadius: "50px",
                    boxShadow: "0 15px 15px black",
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                    marginTop: "-5px",
                    zIndex: 0,
                  }}
                  onClick={() => handleAvatarClick(avatar)}
                />

                {movesGuesses && isCurrentUser === true && (
                  <Badge
                  key={index}
                  badgeContent={movesGuesses} 
                  color={avatar?.moveStatus === "waiting" ? "error" : "primary"}
                  sx={{
                    zIndex: 100,
                    "& .MuiBadge-badge": {
                      top: -5,
                      right: -5,
                      color: "white",
                      backgroundColor: "green"
                    },
                  }}
                  />
                )}
              </div>
            </Badge>
          );
        })}
      </Stack>

      <Profile
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        avatar={selectedAvatar}
        onAddToFavorite={handleAddToFavorite}
        userData={userData}
        t={t}
      />
    </Box>
  );
}

Clock.propTypes = {
  time: PropTypes.number,
  path: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
};

Clock.defaultProps = {
  time: 60,
  path: "https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743417975/Lottie/timer.json",
  width: "100px",
  height: "100px",
  marginTop: "0px",
  marginRight: "0px",
  marginLeft: "0px",
  loop: true,
  autoplay: true,
};