import React from "react";

const CountryFlag = ({ countryCode }) => {
  const isMobile = window.innerWidth <= 600; // Check if the screen size is mobile
  if (!countryCode) return <div></div>;

  const flagUrl = `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <img
        src={flagUrl}
        alt={`Flag of ${countryCode}`}
        style={{
          width: isMobile === false? "60px" : "30px",
          height: isMobile === false? "40px" : "20px",
          borderRadius: "1px",
          boxShadow: "px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      />
      <span>{countryCode.toUpperCase()}</span>
    </div>
  );
};

export default CountryFlag;
