import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Avatar,
  Divider,
  TextField,
  IconButton,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import StarIcon from "@mui/icons-material/Star";
import CountryFlag from "./CountryFlag"; // Adjust path as needed
import BalanceIcon from "@mui/icons-material/Balance";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { sendMessage } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import ChessButton from "./ChessButton";
import NotEnoughCoinsDialog from "./NotEnoughCoinsDialog";

const Profile = ({ open, onClose, avatar, userData }) => {
  const { t } = useTranslation(); // Access the translation function
  const isMobile = window.innerWidth <= 600; // Check if the screen size is mobile
  const [avatarUrl, setAvatarUrl] = useState(avatar?.url || "");
  const [avatarName, setAvatarName] = useState(avatar?.name || "");
  const [editingName, setEditingName] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  React.useEffect(() => {
    // Retrieve the last selected index from localStorage on component mount

    if (userData) {
      setAvatarUrl(userData.url);
      setAvatarName(userData.name);
    }
  }, [userData]); // Run only once on mount

  React.useEffect(() => {
    // Retrieve the last selected index from localStorage on component mount

    if (avatar) {
      setAvatarUrl(avatar.url);
      setAvatarName(avatar.name);
    }
  }, [avatar]); // Run only once on mount

  const handleAddToFavorite = () => {
    const generalMessage = { ...generalMessageTemplate };
    generalMessage.messageType = "add_to_favorite";
    generalMessage.user.userName = avatar.userName;
    sendMessage(generalMessage);
  };

  const handleUpdateName = async () => {
    try {
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "user_data_update";
      generalMessage.user.name = avatarName;
      sendMessage(generalMessage);
    } catch (error) {
      console.error("Error updating name:", error);
    }
  };

  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "DemocraticChess");
    formData.append("folder", "Avatars");

    try {
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dnlgtek2a/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await res.json();
      setAvatarUrl(data.secure_url);

      // deleting an existing avatar url will be done via backend
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "user_data_update";
      generalMessage.user.url = data.secure_url;
      sendMessage(generalMessage);
    } catch (error) {
      console.error("Error uploading avatar:", error);
    }
  };
  const handleRemoveAvatar = async () => {
    if (!avatarUrl) return;

    try {
      // deleting an existing avatar url will be done via backend
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "user_data_update";
      generalMessage.user.url = "remove";
      sendMessage(generalMessage);
      setAvatarUrl("");
    } catch (error) {
      console.error("Error deleting avatar:", error);
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleBuyCoins = () => {
    
    setIsDialogOpen(false);
  };

  const handleAddOrRemove = () => {
    if (userData?.favorites?.includes(avatar?.userName)) {
      // Remove from favorites
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "remove_from_favorite";
      generalMessage.user.favorites = [];
      generalMessage.user.favorites.push(avatar.userName);
      sendMessage(generalMessage);
    } else {
      // Add to favorites
      handleAddToFavorite();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm" // Increased width from "sm" to "md"
      fullWidth
      sx={{
        zIndex: 3000,
        "& .MuiDialog-paper": {
          borderRadius: "10px",
          padding: "18px",
          backgroundColor: "#e0e0e0",
          width: "90%", // Ensures it takes more width on smaller screens
          "@media (max-width: 600px)": {
            width: "95%", // Expand width further on smaller devices
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          backgroundColor: "#3f51b5",
          color: "#fff",
          padding: "8px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            src={avatarUrl || ""}
            alt={avatarName || "Avatar"}
            sx={{ width: 56, height: 56, border: "2px solid #fff" }}
          />
          {
            <div>
              {editingName &&
              localStorage.getItem("browserId") === avatar?.browserId ? (
                <TextField
                  value={avatarName}
                  onChange={(e) => setAvatarName(e.target.value)}
                  onBlur={() => {
                    setEditingName(false);
                    handleUpdateName();
                  }}
                  autoFocus
                  variant="standard"
                />
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="h7"
                    onClick={() => setEditingName(true)}
                    sx={{ cursor: "pointer" }}
                  >
                    {avatarName || t("Choose your player name")}
                  </Typography>
                  {localStorage.getItem("browserId") === avatar?.browserId && (
                    <IconButton onClick={() => setEditingName(true)}>
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            </div>
          }
        </Box>
        <CountryFlag countryCode={avatar?.countryCode} />
      </DialogTitle>

      <DialogContent>
        {/* Use the NotEnoughCoinsDialog component */}
        <NotEnoughCoinsDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onBuyCoins={handleBuyCoins}
          buy={true}
          userData={userData}
        />
        {localStorage.getItem("browserId") === avatar?.browserId && (
          <Box
            sx={{ textAlign: "left", mb: 1, marginTop: "-10px", zIndex: 5000 }}
          >
            <input
              accept="image/*"
              type="file"
              onChange={handleAvatarUpload}
              style={{ display: "none" }}
              id="avatar-upload"
            />
            <label htmlFor="avatar-upload">
              <IconButton component="span">
                <CameraAltIcon />
              </IconButton>
            </label>
            {avatarUrl && (
              <IconButton onClick={handleRemoveAvatar}>
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            zIndex: 2000,
          }}
        >
          {/* Left Section */}
          <Box
            sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}
          >
            {localStorage.getItem("browserId") === avatar?.browserId && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    //fontSize: { xs: "0.8rem", sm: "1rem" }, // Smaller text on mobile
                  }}
                >
                  <Box
                    component="img"
                    src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405234/Pictures/coin.png"}
                    alt="Money Icon"
                    sx={{
                      width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
                    }}
                  />
                  <strong>{t("Total Coins")}:</strong>{" "}
                  {avatar?.userStatistics?.coins || 0}
                </Typography>

                <ChessButton
                  onPress={handleOpenDialog}
                  primaryColor="gold"
                  hoverColor="gold"
                  text={t("Coins")}
                  width="100px"
                  fontColor="black"
                ></ChessButton>
              </Box>
            )}

            <Typography
              variant="body1"
              sx={{ display: "flex", gap: 1, marginTop: "10px" }}
            >
              <Box
                component="img"
                src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743405320/Pictures/star.png"}
                alt="Money Icon"
                sx={{
                  width: { xs: "1.3rem", sm: "1.5rem" }, // Responsive sizing
                }}
              />
              <strong>{t("Total Points")}:</strong>{" "}
              {avatar?.userStatistics?.currentScore || 0}
            </Typography>
            <Typography variant="body1" sx={{ display: "flex", gap: 1 }}>
              <AutoGraphIcon color="secondary" />
              <strong>{t("Best Points")}:</strong>{" "}
              {avatar?.userStatistics?.bestScore}
            </Typography>
            <Typography variant="body1" sx={{ display: "flex", gap: 1 }}>
              <StarIcon color="error" />
              <strong>{t("Total Wins")}:</strong>{" "}
              {avatar?.userStatistics?.totalWins || 0}
            </Typography>
            <Typography variant="body1" sx={{ display: "flex", gap: 1 }}>
              <CloseIcon color="action" />
              <strong>{t("Total Losses")}:</strong>{" "}
              {avatar?.userStatistics?.totalLose || 0}
            </Typography>
            <Typography variant="body1" sx={{ display: "flex", gap: 1 }}>
              <BalanceIcon color="action" />
              <strong>{t("Total Draws")}:</strong>{" "}
              {avatar?.userStatistics?.totalDraw || 0}
            </Typography>
            <Typography variant="body1" sx={{ display: "flex", gap: 1 }}>
              <Box
                component="img"
                src={"https://res.cloudinary.com/dnlgtek2a/image/upload/v1743427066/Pictures/total_games.jpg"} // Path to your JPG image
                alt="Total Games"
                sx={{
                  width: 24, // Adjust size as needed
                  height: 24,
                }}
              />
              <strong>{t("Total Games")}:</strong>{" "}
              {avatar?.userStatistics?.totalGames || 0}
            </Typography>
          </Box>

          {/* Right Section: Circular Ratio Display */}
          <Box
            sx={{
              position: "relative",
              width: isMobile === false ? "150px" : "110px",
              height: isMobile === false ? "150px" : "110px",
              borderRadius: "50%",
              background: "linear-gradient(135deg, #4caf50, #8bc34a)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              flexShrink: 0, // Prevent shrinking when resizing
            }}
          >
            {/* Ratio Number */}
            <Typography
              variant={isMobile === false ? "h4" : "h7"}
              sx={{
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {avatar?.userStatistics?.totalGames > 0
                ? (
                    avatar?.userStatistics?.totalWins /
                    avatar?.userStatistics?.totalGames
                  ).toFixed(2)
                : "N/A"}
            </Typography>

            {/* Ratio Label */}
            <Typography
              variant={isMobile === false ? "h7" : "h7"}
              sx={{
                color: "#fff",
                fontWeight: "medium",
                marginTop: "4px", // Small spacing between number and label
              }}
            >
              {t("Wins / Games")}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: "space-between", padding: "16px" }}>
        {(localStorage.getItem("browserId") === avatar?.browserId) === false &&
          avatar?.userType !== "AI" && (
            <ChessButton
              onPress={handleAddOrRemove}
              beforeIcon={<FavoriteIcon />}
              text={
                userData?.favorites?.includes(avatar?.userName)
                  ? t("Remove from Favorite")
                  : t("Add to Favorite")
              }
            ></ChessButton>
          )}

        <ChessButton
          onPress={onClose}
          text={t("Close")}
          width="120px"
        ></ChessButton>
      </DialogActions>
    </Dialog>
  );
};

export default Profile;
