import React from "react";
import { AwesomeButton } from "react-awesome-button";

const ChessButton = ({
  text,
  onPress,
  size,
  primaryColor = "#4a707d",
  shadowColor = "#212121",
  fontColor = "#ffffff",
  height = "55px",
  width = "250px",
  fontSize = "16px",
  borderRadius = "20px",
  raiseLevel = "10px",
  hoverColor = "#455a64",
  hoverPressure = "4",
  transformSpeed = "0",
  border = "0px solid",
  icon,             // Icon element (JSX)
  iconPosition = "left", // "left" or "right"
  style,
}) => {
  const beforeIcon = iconPosition === "left" ? icon : null;
  const afterIcon = iconPosition === "right" ? icon : null;

  return (
    <AwesomeButton
    
    onPress={onPress}
    //onMouseDown={onPress}
    //onTouchStart={onPress}
    before={beforeIcon}
    after={afterIcon}
    type="primary"
    style={{
      width: width,
      height: height,
      touchAction: "manipulation",
      "--button-default-font-size": fontSize,
      "--button-default-border-radius": borderRadius,
      "--button-raise-level": raiseLevel,
      "--button-primary-border": border,
      "--button-primary-color-hover": hoverColor,
      "--button-hover-pressure": hoverPressure,
      "--transform-speed": transformSpeed,
      "--button-primary-color": primaryColor,
      "--button-primary-color-dark": shadowColor,
      "--button-primary-color-light": fontColor,
      ...style,
    }}
  >
    {text}
  </AwesomeButton>
  );
};

export default ChessButton;
