import React, { useState } from "react";
import { useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  Google as GoogleIcon,
  Facebook as FacebookIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../authMethods/firebaseConfig"; // Ensure Firebase is properly initialized
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next"; // Added import for translations
import EmailIcon from "@mui/icons-material/Email"; // Make sure this is imported
import KeyIcon from "@mui/icons-material/Key";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GppGoodIcon from "@mui/icons-material/GppGood";
import PasswordIcon from "@mui/icons-material/Password";
import { GENERAL } from "../general/General";

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize translations

  useEffect(() => {
    // Facebook SDK init
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "952964853669494", // ✅ Your App ID
        xfbml: true,
        version: "v22.0",         // ✅ Use the latest version you're targeting
      });
      window.FB.AppEvents.logPageView();
    };
  
    // Load the SDK script only once
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  // Google Sign-In
  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/main"); // Redirect on success
    } catch (error) {
      Swal.fire({
        title: t("Error"),
        text: t(error.message),
        icon: "error",
        draggable: true,
        confirmButtonText: t("OK"),
      });
    } finally {
      setLoading(false);
    }
  };

  // Facebook Sign-In
  const handleFacebookSignIn = async () => {
    setLoading(true);
    try {
      const provider = new FacebookAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/main"); // Redirect on success
    } catch (error) {
      Swal.fire({
        title: t("Error"),
        text: t(error.message),
        icon: "error",
        draggable: true,
        confirmButtonText: t("OK"),
      });
    } finally {
      setLoading(false);
    }
  };

  // Email & Password Sign-In
  const handleEmailSignIn = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        setLoading(false);

        Swal.fire({
          title: t("Error"),
          text: t("Please verify your email before logging in"),
          icon: "error",
          draggable: true,
          confirmButtonText: t("OK"),
        });
        return;
      }
      navigate("/main");
    } catch (error) {
      Swal.fire({
        title: t("Error"),
        text: t(error.message),
        icon: "error",
        draggable: true,
        confirmButtonText: t("OK"),
      });
    } finally {
      setLoading(false);
    }
  };

  // Resend verification email
  const resendVerificationEmail = async (event) => {
    const user = auth.currentUser;

    if (user) {
      try {
        await sendEmailVerification(user);

        Swal.fire({
          title: t("Verification email resent. Please check your email"),
          icon: "success",
          draggable: true,
          confirmButtonText: t("OK"),
        });
        return {
          success: true,
          message: "Verification email has been resent.",
        };
      } catch (error) {
        Swal.fire({
          title: t("Error resending verification email:"),
          icon: "error",
          draggable: true,
          confirmButtonText: t("OK"),
        });
        return { success: false, message: error.message };
      }
    } else {
      Swal.fire({
        title: t("Please log in first to resend the verification email"),
        icon: "error",
        draggable: true,
        confirmButtonText: t("OK"),
      });
      return {
        success: false,
        message: "Please log in first to resend the verification email",
      };
    }
  };

  const forgotPassword = async () => {
    try {
      if (!email) {
        Swal.fire({
          title: t("Please provide your email address"),
          icon: "error",
          draggable: true,
          confirmButtonText: t("OK"),
        });
        return { success: false, message: "Email is required" };
      }

      await sendPasswordResetEmail(auth, email);

      Swal.fire({
        title: t("Reset password email sent. Please check your inbox"),
        icon: "success",
        draggable: true,
        confirmButtonText: t("OK"),
      });

      return {
        success: true,
        message: "Reset password email sent. Please check your email.",
      };
    } catch (error) {
      Swal.fire({
        title: t("Error sending reset password email:"),
        icon: "error",
        draggable: true,
        confirmButtonText: t("OK"),
      });
      return { success: false, message: error.message };
    }
  };

  const handleSignUp = async () => {
    navigate("/signup");
  };

  // Passkey Authentication (WebAuthn)
  const handlePasskeySignIn = async () => {
    try {
      const publicKey = {
        challenge: new Uint8Array(32),
        rp: { name: "Democratic-Chess App" },
        user: {
          id: new Uint8Array(16),
          name: email,
          displayName: email,
        },
        pubKeyCredParams: [{ type: "public-key", alg: -7 }],
        authenticatorSelection: { userVerification: "preferred" },
        timeout: 60000,
      };

      const credential = await navigator.credentials.create({ publicKey });
      console.log("Passkey credential:", credential);

      Swal.fire({
        title: t("Passkey authentication successful!"),
        icon: "success",
        draggable: true,
        confirmButtonText: t("OK"),
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Passkey authentication failed:", error);

      Swal.fire({
        title: t("Passkey authentication failed"),
        icon: "error",
        draggable: true,
        confirmButtonText: t("OK"),
      });
    }
  };

  const handleBack = () => {
    navigate(-1); // 👈 This navigates one step back in history
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
       
        backgroundImage: GENERAL.BACKGROUND,
       
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={2}
        color={"black"}
      >
        <Typography variant="h7" gutterBottom sx={{ color: "white" }}>
          {t("SIGN IN")}
        </Typography>

        <Box
          sx={{
            //display: "flex",
            flexDirection: "column", // Arrange boxes horizontally
            justifyContent: "space-around", // Evenly space items
            alignItems: "center", // Align items vertically
            textAlign: "center",
            backgroundColor: "#f1f8e9",
            border: "5px solid #ccc", // Add border around the Stack
            padding: "5px",
            borderRadius: "10px",
            boxShadow: "8px 2px 3px black", // Optional shadow for emphasis
            margin: "5px", // Optional margin around the box
          }}
        >
          {/* Google Sign-In */}
          <Button
            fullWidth
            variant="contained"
            color="error"
            startIcon={<GoogleIcon />}
            onClick={handleGoogleSignIn}
            disabled={loading}
            sx={{
              mb: 1,
              mt: 1,
              bgcolor: "#db4437",
              "&:hover": { bgcolor: "#b33629" },
            }}
          >
            {t("Sign in with Google")}
          </Button>

          {/* Facebook Sign-In */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<FacebookIcon />}
            onClick={handleFacebookSignIn}
            disabled={loading}
            sx={{
              mb: 1,
              bgcolor: "#1877f2",
              "&:hover": { bgcolor: "#1558b0" },
            }}
          >
            {t("Sign in with Facebook")}
          </Button>

          <Button
            fullWidth
            variant="contained"
            color="success"
            startIcon={<LockIcon />}
            onClick={handlePasskeySignIn}
            disabled={loading}
            sx={{ mb: 1 }}
          >
            {t("Sign in with Passkey")}
          </Button>
        </Box>

        <Divider sx={{ width: "100%", mb: 1, color: "white" }}>
          {t("OR")}
        </Divider>

        <Box
          sx={{
            //display: "flex",
            flexDirection: "column", // Arrange boxes horizontally
            justifyContent: "space-around", // Evenly space items
            alignItems: "center", // Align items vertically
            textAlign: "center",
            backgroundColor: "#f1f8e9",
            border: "5px solid #ccc", // Add border around the Stack
            padding: "5px",
            borderRadius: "10px",
            boxShadow: "8px 2px 3px black", // Optional shadow for emphasis
            margin: "5px", // Optional margin around the box
          }}
        >
          {/* Email & Password Sign-In */}
          <form onSubmit={handleEmailSignIn} style={{ width: "100%" }}>
            <TextField
              fullWidth
              label={t("Email")}
              type="email"
              variant="outlined"
              margin="normal"
              required
              value={email}
              size="small" // ✅ makes it more compact
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label={t("Password")}
              type="password"
              variant="outlined"
              margin="normal"
              required
              value={password}
              size="small" // ✅ makes it more compact
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              type="submit"
              disabled={loading}
              sx={{
                mt: 1,
                bgcolor: "#00b0ff",
                "&:hover": { bgcolor: "#0091ea" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1, // spacing between icon and text
              }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <EmailIcon fontSize="small" />
                  {t("Sign in with Email")}
                </>
              )}
            </Button>
          </form>

          <Button
            fullWidth
            variant="contained"
            onClick={resendVerificationEmail}
            disabled={loading}
            sx={{
              mt: 1,
              bgcolor: "#5d4037",
              "&:hover": { bgcolor: "#424242" },
            }}
          >
            <GppGoodIcon fontSize="small" sx={{ mr: 0.5 }} />
            {t("Resend Verification Email")}
          </Button>

          <Button
            fullWidth
            variant="contained"
            onClick={forgotPassword}
            disabled={loading}
            sx={{
              mt: 1,
              bgcolor: "#8d6e63",
              "&:hover": { bgcolor: "#424242" },
            }}
            //color="secondary"
          >
            <PasswordIcon fontSize="small" sx={{ mr: 1 }} />
            {t("Forgot Password")}
          </Button>
        </Box>

        <Divider sx={{ width: "100%", mt: 1, color: "gold" }}>
          {t("NO ACCOUNT YET?")}
        </Divider>
      </Box>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSignUp}
        disabled={loading}
        sx={{ mt: 1, bgcolor: "#ff5722", "&:hover": { bgcolor: "#f57f17" } }}
      >
        <KeyIcon fontSize="small" sx={{ mr: 1 }} />
        {loading ? <CircularProgress size={24} /> : t("Sign Up")}
      </Button>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleBack}
        disabled={loading}
        sx={{
          mt: 5,
          marginBottom: "5px",
          bgcolor: "#778899",
          "&:hover": { bgcolor: "#87ceeb" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <ArrowBackIcon fontSize="small" />
        {t("Back")}
      </Button>
    </Container>
  );
};

export default SignIn;
