import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { addListener, removeListener, sendMessage } from "./websocketManager";
import useGeoLocation from "react-ipgeolocation";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import { useAuth } from "../AuthContext";
import { useUser } from "../authMethods/UserContext";


export const useWebSocketManager = () => {
  const [generalMessage, setGeneralMessage] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("connecting");

  const hasSentCountryCode = useRef(false);
  const location = useGeoLocation();
  const { currentUser } = useAuth();
  const { setUserData } = useUser();
  const navigate = useNavigate();

  // Navigate to chess page when game starts
  const startGame = useCallback(
    (message) => {
      setGeneralMessage(message);

      const gameId = message?.game?.gameId;
      if (!gameId) {
        console.error("Missing game ID", message);
        return;
      }

      localStorage.setItem("lastGameId", gameId);
      navigate("/chess", { state: { generalMessage: message } });
    },
    [navigate]
  );

  // Send user country code once, when available
  useEffect(() => {
    const countryCode = location?.country;
    if (countryCode && !hasSentCountryCode.current) {
      hasSentCountryCode.current = true;

      const message = { ...generalMessageTemplate };
      message.messageType = "user_country_code";
      message.user.countryCode = countryCode;

      sendMessage(message);
    }
  }, [location?.country]);

  // Handle incoming WebSocket messages
  useEffect(() => {
    const handleWebSocketEvent = (event) => {
      if (event.type === "status") {
        setConnectionStatus(event.status);
        return;
      }

      if (event.type !== "message") return;
      const message = event.message;
      setGeneralMessage(message);

      switch (message.messageType) {
        case "init_game":
        case "continue_game":
          startGame(message);
          break;

        case "get_browser_Id_request": {
          const browserId = localStorage.getItem("browserId");
          const countryCode = location?.country;

          const response = { ...generalMessageTemplate };
          response.messageType = "get_browser_Id_response";
          response.browserId = browserId;
          response.user.socialUser = currentUser;
          response.user.countryCode = countryCode;

          sendMessage(response);

          // Send updated country if changed
          if (countryCode && countryCode !== response.user.countryCode) {
            const countryUpdate = { ...generalMessageTemplate };
            countryUpdate.messageType = "user_country_code";
            countryUpdate.user.countryCode = countryCode;
            sendMessage(countryUpdate);
          }
          break;
        }

        case "set_browser_Id":
          localStorage.setItem("browserId", message.browserId);
          break;

        case "user_data":
        case "game_over":
          setUserData(message.user);
          break;

        case "user_logged_out":
            // 👇 Clear global user data without calling Firebase signOut
            setUserData(null);
            navigate("/login");
            localStorage.removeItem("browserId", null);
            //window.location.href = "/login";
            //window.location.reload(); // Ensures a fresh state after redirect
            break;

        case "favorites_data":
          // You can process favorite stats here if needed
          break;

        default:
          break;
      }
    };

    addListener(handleWebSocketEvent);
    return () => removeListener(handleWebSocketEvent);
  }, [currentUser, location?.country, startGame, setUserData]);

  return {
    connectionStatus,
    generalMessage,
    sendMessage,
  };
};
