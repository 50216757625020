import React, { useRef, useEffect, useState, forwardRef } from "react";

const VideoPlayer = forwardRef(({ videoSrc, isSelected, autoPlay = true, loop = false, muted = false, style }, ref) => {
  const videoRef = useRef(null);
  const [,setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      if (isSelected) {
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => setIsPlaying(true))
            .catch((error) => {
              console.warn("Autoplay prevented, muting video and trying again.", error);
              if(videoRef.current){
                videoRef.current.muted = true;
                videoRef.current.play().catch(console.error);
              }
              
            });
        }
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setIsPlaying(false);
      }
    }
  }, [isSelected]); // Runs whenever isSelected changes

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", overflow: "hidden", ...style }}>
      <video
        ref={videoRef}
        muted={muted}
        loop={loop}
        autoPlay={false} // ❗ Handled in useEffect
        playsInline
        style={{ width: "100%", height: "100%",  position: "relative" }}
      >
        <source src={videoSrc} type="video/mp4" />
        <source src={videoSrc} type="video/webm" />
        <p>Video cannot be played. Please check the source.</p>
      </video>
    </div>
  );
});

export default VideoPlayer;
