import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  sendMessage,
  addListener,
  removeListener,
} from "../services/websocketManager";
import { LottieAnimation } from "../lottie/LottieAnimation.js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import useOpponentsList from "../entities/useOpponentsList.js";
import useLevelsList from "../entities/useLevelsList.js";
import Profile from "./Profile.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Share } from "./Share";
import { GENERAL } from "../general/General";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import ChessButton from "./ChessButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Swal from 'sweetalert2'
const Favorites = ({ userDataFromApp, updateBackground }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog
  const location = useLocation();
  const { userData, joinGame } = location.state || {}; // Use optional chaining to prevent crashes if state is undefined
  const [open, setOpen] = useState(false);
  const [friendUrl, setFriendUrl] = useState(null);
  
  const opponents = useOpponentsList();
  const levels = useLevelsList();

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = window.innerWidth <= 600;
  const mobileFontSize = "10px";
  const regularFontSize = "18px";
  const mobileWidth = "160px";
  const regularWidth = "250px";
  const mobileHeight = "40px";
  const regularHeight = "60px";
  const MAX_HEIGHT_MOBILE = "700px";  // Max height for mobile
  const MAX_HEIGHT_DESKTOP = "700px"; // Max height for desktop
 
  const handleDeleteSelected = () => {
    setDialogOpen(true); // Open the confirmation dialog
  };

  const confirmDelete = () => {
    deleteSelected();
    setSelectedRows([]); // Clear selected rows
    setDialogOpen(false); // Close the dialog
  };

  const cancelDelete = () => {
    setDialogOpen(false); // Close the dialog without deleting
  };

  useEffect(() => {
    if (joinGame || userData || userDataFromApp) {
      // If the user is joining a game, invite the selected players
      //console.log("Inviting selected players to the game...");
    }
  }, [userData, joinGame, userDataFromApp]);

  useEffect(() => {
    updateBackground({
      image: GENERAL.BACKGROUND
    });
    // Define a function to handle WebSocket events
    const handleWebSocketEvent = (event) => {
      if (
        event.type === "message" &&
        event.message.messageType === "favorites_data"
      ) {
        setFavorites(event.message.favoriteStatistics || []);
        setLoading(false); // Stop loading spinner
      }
    };
    // Subscribe to WebSocket events
    addListener(handleWebSocketEvent);

    // Request favorites data from the server when the component is mounted
    requestForFavorites();

    // Cleanup on component unmount
    return () => {
      removeListener(handleWebSocketEvent);
    };
  }, [updateBackground]);

  function requestForFavorites() {
    const generalMessage = {
      messageType: "get_favorites",
    };
    sendMessage(generalMessage);
  }

  function goBack() {
    navigate(-1); // Navigates back to the previous page
  }

  const handleRowSelect = (username) => {
    setSelectedRows((prev) =>
      prev.includes(username)
        ? prev.filter((user) => user !== username)
        : [...prev, username]
    );
  };

  const deleteSelected = () => {
    const generalMessage = {
      messageType: "remove_from_favorite",
      user: {
        favorites: selectedRows,
      },
    };
    sendMessage(generalMessage);
    console.log("Deleted users:", selectedRows);
    setSelectedRows([]);
  };

  const inviteSelectedToGame = () => {
    if (selectedRows && selectedRows.length > 0) {
      let invited = selectedRows.length;
      let lastOpponentHumanOrAI = localStorage.getItem("lastOpponentHumanOrAI");
      let lastAILevel = localStorage.getItem("lastAILevel");
      let lastTimeLimit = localStorage.getItem("lastTimeLimit");

      let level = localStorage.getItem("lastSelectedIndexLevel");
      let levelsObj = levels[level];

      let opponent = localStorage.getItem("lastSelectedOpponentIndex");
      let opponentObj = opponents[opponent];
      const generalMessage = { ...generalMessageTemplate };

      if (opponentObj.players !== invited + 1) {
       
        Swal.fire({
          title: t("Error"),
          text: t("Player count mismatch! Assemble the right team and get ready to play!"),
          icon: 'error',
          confirmButtonText: t("OK"),
        })
        return;
      }

      generalMessage.messageType = "invite_to_game";
      generalMessage.gameInfo.gameOpponentId = opponentObj.gameOpponentId;
      generalMessage.gameInfo.gameLevelId = levelsObj.gameLevelId;
      generalMessage.gameInfo.pointsLose = levelsObj.lose;
      generalMessage.gameInfo.pointsWin = levelsObj.win;
      generalMessage.gameInfo.pointsDraw = levelsObj.draw;
      generalMessage.gameInfo.gameTeamType = opponentObj.gameType;
      generalMessage.gameInfo.userType = "Human";
      generalMessage.gameInfo.opponentType = lastOpponentHumanOrAI;
      generalMessage.gameInfo.secondsBasedOnGameTimeType = lastTimeLimit;
      generalMessage.gameInfo.aiLevel = lastAILevel;
      generalMessage.gameInfo.invited = selectedRows;

      sendMessage(generalMessage);
      console.log("Invited users:", selectedRows);
    }
  };

  const inviteToJoin = () => {
    let id = userData?.id;
    if (!id) {
      id = userDataFromApp?.id;
    }
    let gameURL = GENERAL.HOST + "friend/" + id;
    setFriendUrl(gameURL); // Set the current game URL
   
    setTimeout(() => {
      setOpen(true); 
    }, 50); // delay slightly to let the button animation finish
  };

  const handleAvatarClick = (userStatistics) => {
    setSelectedAvatar(userStatistics);
    setProfileOpen(true);
  };

  const handleCloseProfile = () => {
    setProfileOpen(false);
    setSelectedAvatar(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LottieAnimation
          path={"https://res.cloudinary.com/dnlgtek2a/raw/upload/v1743417798/Lottie/heart.json"}
          width="70px"
          autoplay
          loop
        />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: isMobile ? 2 : 5 }}>
      {/* Action Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 1,
          marginBottom: 3,
        }}
      >
       

      <ChessButton
        onPress={goBack}
        icon={<ArrowBackIcon sx={{ fontSize: 20 }} />} // pass the MUI icon here
        beforeIcon={<ArrowBackRoundedIcon />}
        text={t("Favorite_Back")}
        fontSize={isMobile === true? mobileFontSize: regularFontSize}
        width={isMobile === true? mobileWidth: regularWidth}
        height={isMobile === true? mobileHeight: regularHeight}
        ></ChessButton>

        <ChessButton
          onPress={inviteToJoin}
          icon={<ShareIcon sx={{ fontSize: 20 }} />} // pass the MUI icon here
          text={t("Share")}
          fontSize={isMobile === true? mobileFontSize: regularFontSize}
          width={isMobile === true? mobileWidth: regularWidth}
          height={isMobile === true? mobileHeight: regularHeight}
        ></ChessButton>
      </Box>

      

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("Invite Your Friend")}</DialogTitle>
        <DialogContent>
          <Share currentUrl={friendUrl} />
        </DialogContent>
      </Dialog>

      <div>
        {(!favorites || favorites.length === 0) && (
          <div style={{ textAlign: "center", padding: "20px", color: "gray" }}>
            <h3>{t("Hey, you don't have any favorites yet!")}</h3>
            <p>{t("Add some friends to your favorites to see them here")}</p>
          </div>
        )}
        {/* Table */}
        {favorites && favorites.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: isMobile ? MAX_HEIGHT_MOBILE : MAX_HEIGHT_DESKTOP, // Set max height
              overflowY: "auto", // Enable vertical scrolling
              borderRadius: "15px",
            }}
          >
            <Table
              stickyHeader={isMobile}
              sx={{
                minWidth: 300,
                //border: "5px solid #ccc",
                boxShadow: "20 20px 15px black",
                borderRadius: "15px",
              }}
              aria-label="favorites table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    //border: "5px solid #ccc",
                    backgroundColor: "#edf2fa", // Background color
                    boxShadow: "10 20px 15px black",
                    borderRadius: "15px",
                  }}
                >
                  <TableCell
                    sx={{ textAlign: "center", verticalAlign: "middle" }}
                  />
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {t("Name")}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {t("Info")}
                  </TableCell>

                  {isMobile === false && (
                    <TableCell
                      sx={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("Games")}
                    </TableCell>
                  )}

                  {isMobile === false && (
                    <TableCell
                      sx={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("Wins")}
                    </TableCell>
                  )}

                  {isMobile === false && (
                    <TableCell
                      sx={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("Losses")}
                    </TableCell>
                  )}

                  {isMobile === false && (
                    <TableCell
                      sx={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("Draws")}
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {t("Win Ratio")}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {t("Online")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {favorites.map((user, index) => {
                  const ratio = user.userStatistics.totalGames
                    ? (
                        user.userStatistics.totalWins /
                        user.userStatistics.totalGames
                      ).toFixed(2)
                    : "0.00";

                  return (
                    <TableRow key={index}>
                      <TableCell
                        padding="checkbox"
                        sx={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        <Checkbox
                          checked={selectedRows.includes(
                            user.userStatistics.userName
                          )}
                          onChange={() =>
                            handleRowSelect(user.userStatistics.userName)
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {user.userStatistics.name}
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        <Avatar
                          src={user.url}
                          alt={user.name}
                          onClick={() => handleAvatarClick(user)}
                          sx={{
                            margin: "0 auto",
                            cursor: "pointer",
                            border: "2px solid #ccc",
                            boxShadow: "0 20px 15px black",
                            borderRadius: "25px", // Rounded corners
                          }}
                        />
                      </TableCell>
                      {isMobile === false && (
                        <TableCell
                          sx={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                          {user.userStatistics.totalGames}
                        </TableCell>
                      )}

                      {isMobile === false && (
                        <TableCell
                          sx={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                          {user.userStatistics.totalWins}
                        </TableCell>
                      )}

                      {isMobile === false && (
                        <TableCell
                          sx={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                          {user.userStatistics.totalLose}
                        </TableCell>
                      )}

                      {isMobile === false && (
                        <TableCell
                          sx={{ textAlign: "center", verticalAlign: "middle" }}
                        >
                          {user.userStatistics.totalDraw}
                        </TableCell>
                      )}

                      <TableCell
                        sx={{
                          textAlign: "center",
                          verticalAlign: "middle",

                          color: "blue",
                          // border: "5px solid #ccc",
                          //   boxShadow: "0 1px 1px black",
                          //   borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {ratio}%
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          verticalAlign: "middle",
                          color: user.online === true ? "green" : "red",
                        }}
                      >
                        {user.online === true ? t("yes") : t("no")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      {/* Under the Grid Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        {selectedRows.length > 0 && (
          <ChessButton
          fontSize={isMobile === true? mobileFontSize: regularFontSize}
          onPress={handleDeleteSelected}
          icon={<DeleteIcon />}
          text={t("Delete")}
          width={isMobile === true? mobileWidth: regularWidth}
          height={isMobile === true? mobileHeight: regularHeight}
          ></ChessButton>
        )}

      {selectedRows.length > 0 && (
          <ChessButton
            onPress={inviteSelectedToGame}
            icon={<GroupAddIcon />}
            text={t("Play with Friends")}
            fontSize={isMobile === true? mobileFontSize: regularFontSize}
            width={isMobile === true? mobileWidth: regularWidth}
            height={isMobile === true? mobileHeight: regularHeight}
          ></ChessButton>
        )}

       
      </Box>

      <ChessButton
          onPress={requestForFavorites}
          icon={<RefreshIcon sx={{ fontSize: 20 }} />} // pass the MUI icon here
          text={t("Refresh")}
          fontSize={isMobile === true? mobileFontSize: regularFontSize}
          width={isMobile === true? mobileWidth: regularWidth}
          height={isMobile === true? mobileHeight: regularHeight}
        ></ChessButton>

      {/* Profile Dialog */}
      <Profile
        open={profileOpen}
        onClose={handleCloseProfile}
        avatar={selectedAvatar}
        userData={userDataFromApp}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={cancelDelete}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          {t("Confirm Delete")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            {t(
              "Are you sure you want to delete the selected favorites? This action cannot be undone"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Favorites;
