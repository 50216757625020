import { useEffect } from "react";
import { useAuth } from "../AuthContext";
import { sendMessage } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";

const UserDataSync = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    
    // Send user data to the server via WebSocket when user logs in
    if (currentUser) {
        const generalMessage = { ...generalMessageTemplate };
        generalMessage.messageType = "user_login";
        generalMessage.user.socialUser = currentUser;
        generalMessage.user.email = currentUser.email;
        generalMessage.user.userName = currentUser.email;
        sendMessage(generalMessage);
    }else{
        const generalMessage = { ...generalMessageTemplate };
        generalMessage.messageType = "user_logged_out";
        //sendMessage(generalMessage);
    }

  }, [currentUser]);

  return null; // Invisible component
};

export default UserDataSync;
 