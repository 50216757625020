import React from "react";
import { Radio, Box } from "@mui/material";

const CustomRadio = ({ uncheckedIconPath, checkedIconPath, width_c, height_c, width_u, height_u, ...props }) => {
  return (
    <Radio
      {...props}
      checkedIcon={
        <Box
          component="img"
          src={checkedIconPath}
          alt="checked-icon"
          sx={{
            width: width_c, // Adjust size as needed
            height: height_c,
            border: "4px solid #ccc", // #ccc
            boxShadow: "0 15px 15px black",
            borderRadius: "10px", // Rounded corners
          }}
        />
      }
      icon={
        <Box
          component="img"
          src={uncheckedIconPath}
          alt="u"
          sx={{
            width: width_u, // Adjust size as needed
            height: height_u,
            border: "4px solid #ccc",
            boxShadow: "0 15px 15px black",
            borderRadius: "10px", // Rounded corners
          }}
        />
      }
    />
  );
};

export default CustomRadio;
