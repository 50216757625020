// API Endpoints
export const GENERAL = {
    NAME: "Democratic Chess",
    VERSION: "1.0.0",
    SERVER_URL_HTTP: "ws://localhost:8080/game/ws",
    SERVER_URL_HTTPS: "wss://api.democraticchess.com/game/ws", //"wss://middle-goose-democraticchess-81d6dac7.koyeb.app/game/ws",//"wss://democraticchess.duckdns.org/game/ws", 
    HOST: "https://democraticchess.com/",
    SHARE_IMAGE: "https://landing.democraticchess.com/img/about.webp",
    BACKGROUND: "https://res.cloudinary.com/dnlgtek2a/image/upload/v1743414461/Pictures/wmmklcskexrcb7b5zutp.jpg",
  };
  
 