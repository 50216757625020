import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

export function LottieAnimation({ path, width = "260px", height = "260px", loop=true, autoplay=true, onClick }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: loop,
      autoplay: autoplay,
      path,
    });

    return () => animation.destroy(); // Cleanup on unmount
  }, [autoplay, loop, path]);

  return (
    <div
      ref={containerRef}
      style={{ width, height, cursor: "pointer", zIndex: 10 }}
      onClick={onClick} // Attach the onClick handler
    />
  );
}
