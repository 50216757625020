import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useParams, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { sendMessage } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";
import MovingText from "react-moving-text";
import ChessButton from "./ChessButton";
import "react-awesome-button/dist/styles.css";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { GENERAL } from "../general/General";

const styles = (theme) => ({
  dialog: {
    borderRadius: theme.spacing(2),
    maxWidth: "500px",
    width: "90%",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  listItem: {
    borderRadius: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
});

const WelcomePage = ({ userData, friend, updateBackground }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const handleOpenWhyDialog = () => setIsDialogOpen(true);
  const handleCloseWhyDialog = () => setIsDialogOpen(false);

  React.useEffect(() => {
    if (friend) {
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "friend_joined";
      generalMessage.user.id = id;

      sendMessage(generalMessage);
    }
    updateBackground({
      image: GENERAL.BACKGROUND,
    });
  }, [userData, friend, id, updateBackground]);

  React.useEffect(() => {
    if (userData) {
      console.log(userData);
    }
  }, [userData]);

  const handleLogin = () => {
    navigate("/login");
  };

  // const handleSignUp = () => {
  //   navigate("/signup");
  // };

  const handlePlayAsGuest = () => {
    navigate("/main");
  };

  const handleLearnMore = () => {
    window.open(
      "https://landing.democraticchess.com",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  //top: isMobile ? "250px" : "400px", // Adjust vertical position
                  
                 
                  width: isMobile ? "270px" : "420px",
                  height: isMobile ? "270px" : "420px",
                  backgroundImage: `url(https://res.cloudinary.com/dnlgtek2a/image/upload/v1743426566/Pictures/logo.jpg)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: 1,
                  zIndex: 10,
                  borderRadius: "10px",
                 
                }}
              />

      <Typography
        variant={isMobile ? "body1" : "h5"}
        sx={{
          color: "white",
          textAlign: "center",
          marginBottom: isMobile ? 3 : 4,
          maxWidth: isMobile ? "90%" : "70%",
          marginTop: isMobile ? 4 : 8,
        }}
      >
        {t(
          "The revolutionary way to play chess where strategy meets collaboration"
        )}
        <MovingText />
      </Typography>

      {userData?.socialUser && (
        <ChessButton
        icon={<PlayArrowIcon sx={{ fontSize: 20 }} />}
          width="200px"
          onPress={handlePlayAsGuest}
          text={t("Start your game")}
        ></ChessButton>
      )}

      {!userData?.socialUser && (
        <Stack direction="column" spacing={1} alignItems="center" mt={isMobile ? 2 : 3}>
          <Stack direction={isMobile ? "column" : "row"} spacing={2}>
            <ChessButton width="200px"  onPress={handleLogin} icon={<KeyIcon sx={{ fontSize: 20 }} />} text={t("Sign up / Login")} />
            
            <ChessButton width="200px" onPress={handlePlayAsGuest} icon={<PlayArrowIcon sx={{ fontSize: 20 }} />} text={t("Play as guest")} />
            {/* <ChessButton width="200px" onPress={handleLearnMore} text={t("Learn More")} /> */}
          </Stack>
          <Stack direction={isMobile ? "row" : "row"} spacing={2}>
          <Button
            onClick={handleOpenWhyDialog}
            variant="text"
            size="small"
            sx={{
              color: "white",
              textTransform: "none",
              mt: 1,
              fontStyle: "italic",
              opacity: 0.85,
              fontSize: "0.9rem", // Increase font size here
              "&:hover": {
                opacity: 1,
                textDecoration: "underline",
              },
            }}
          >
            {t("Why sign up?")}
          </Button>

          <Button
            onClick={handleLearnMore}
            variant="text"
            size="small"
            sx={{
              color: "white",
              textTransform: "none",
              mt: 1,
              fontStyle: "italic",
              opacity: 0.85,
              fontSize: "0.9rem", // Increase font size here
              "&:hover": {
                opacity: 1,
                textDecoration: "underline",
              },
            }}
          >
            {t("Learn more")}
          </Button>
          </Stack>
        </Stack>
      )}

<Dialog open={isDialogOpen} onClose={handleCloseWhyDialog} PaperProps={{ sx: styles(theme).dialog }}>
  <DialogTitle sx={styles(theme).dialogTitle}>
    <Typography variant="h6">{t("Why sign up?")}</Typography>
    <IconButton aria-label="close" onClick={handleCloseWhyDialog}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
    <List>
      <ListItem sx={styles(theme).listItem}>
        <ListItemIcon sx={styles(theme).listItemIcon}>
          <SaveAltIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={t("Save and search for your games")} />
      </ListItem>
      <ListItem sx={styles(theme).listItem}>
        <ListItemIcon sx={styles(theme).listItemIcon}>
          <GroupAddIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={t("Invite and play with friends")} />
      </ListItem>
      <ListItem sx={styles(theme).listItem}>
        <ListItemIcon sx={styles(theme).listItemIcon}>
          <MonetizationOnIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={t("Receive daily coins when visiting")} />
      </ListItem>
      <ListItem sx={styles(theme).listItem}>
        <ListItemIcon sx={styles(theme).listItemIcon}>
          <ShoppingCartIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={t("Purchase coins")} />
      </ListItem>
      <ListItem sx={styles(theme).listItem}>
        <ListItemIcon sx={styles(theme).listItemIcon}>
          <EmojiEventsIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={t("And even more features...")} />
      </ListItem>
    </List>
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        handleCloseWhyDialog();
        handleLogin();
      }}
      sx={{ marginTop: 2, width: "100%" }}
    >
      {t("Sign up / Login")}
    </Button>
  </DialogContent>
</Dialog>
    </Box>
  );
};

export default WelcomePage;